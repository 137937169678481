import client, { parseQueryParams } from "./client";

export const fetchVoicemails = (
  user,
  page = 1,
  page_size = 25,
  filter = null
) => {
  let params = { page: page, page_size: page_size };

  if (filter) {
    params = { ...params, ...filter };
  }

  const endpoint = `/user/${user}/voicemail` + parseQueryParams(params);
  return client.get(endpoint);
};
