import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtText from "./NtText";
import NtMaterialIcon from "./NtMaterialIcon";

function NtErrorView({
  icon = "information",
  title,
  message,
  renderBody,
  containerStyle,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name={icon} size={20} color={colors.lighGray} />
        <NtText style={styles.title}>{title}</NtText>
      </View>
      {message && (
        <NtText style={[styles.message, { marginTop: 8 }]}>{message}</NtText>
      )}
      {renderBody && renderBody}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    padding: 15,
    borderRadius: 6,
  },
  title: {
    color: colors.lighGray,
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 5,
  },
  message: {
    color: colors.lighGray,
    fontSize: 14,
    fontWeight: "400",
    marginLeft: 5,
  },
});

export default NtErrorView;
