import React, { useEffect, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { parseDisplayDate } from "../../common/utils/ControllerUtils";

function NtChatLeftItem({ item }) {
  return (
    <View style={{ alignItems: "flex-start", paddingTop: 2, paddingBottom: 4 }}>
      <View style={styles.container}>
        <NtText style={styles.text}>{item.mMessageText}</NtText>
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <NtMaterialIcon
            name="clock-time-five-outline"
            color={colors.darker}
          />
          <NtText style={[styles.date, { marginLeft: 5 }]}>
            {parseDisplayDate(item.mDate)}
          </NtText>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    padding: 10,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    alignItems: "flex-start",
    minWidth: 100,
    maxWidth: "87%",
  },

  text: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "500",
  },
  date: {
    color: colors.darker,
    fontSize: 12,
  },
  image: {
    width: 200,
    height: 250,
    resizeMode: "cover",
    borderRadius: 6,
  },
});

export default NtChatLeftItem;
