import client from "./client";

// export const authenticateUser = async (username, password) => {
//   const response = await client.get(`/user/${username}`);

//   return new Promise((resolve, reject) => {
//     if (response.ok && response.data.password === password) {
//       resolve({ ok: true, data: response.data });
//     } else {
//       resolve({ ok: false });
//     }
//   });
// };

export const authenticate = (username, password) => {
  const body = {
    username: username,
    password: password,
  };
  return client.post("/auth", body);
};

export const authenticateRequestCode = (username) => {
  const body = {
    type: "login",
    owner: username,
    ownerType: "extension",
  };

  return client.post("/securitycode/generate", body);
};

export const authenticateVerifyCode = (username, code) => {
  const body = {
    type: "login",
    owner: username,
    code: code,
  };

  return client.post("/securitycode/validate", body);
};
