import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";
import NtActivityIndicator from "./NtActivityIndicator";

function NtLoadMoreButton({
  title = "Load More",
  loadingText,
  loading,
  onPress,
  containerStyle,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      {loading && (
        <NtActivityIndicator
          containerStyle={{ marginLeft: 10 }}
          color={colors.darkest}
          size={"small"}
        />
      )}
      {loading && loadingText && (
        <NtText style={[styles.text, { marginLeft: 10 }]}>{loadingText}</NtText>
      )}
      {!loading && <NtText style={styles.text}>{title}</NtText>}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighter,
    alignItems: "center",
    justifyContent: "center",
    padding: 25,
    flexDirection: "row",
  },
  text: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "500",
  },
});

export default NtLoadMoreButton;
