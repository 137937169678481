import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import { useMobile } from "../../hooks/useMobile";

function NtCellphoneSection(props) {
  const isMobile = useMobile();
  const renderIcon = (image, text, containerStyle) => {
    return (
      <View style={[{ alignItems: "center" }, containerStyle]}>
        <Image
          style={[styles.icons, isMobile ? { width: 50, height: 50 } : {}]}
          source={image}
        />
        <NtText
          style={{
            textAlign: "center",
            color: "white",
            fontSize: 12,
            fontWeight: "600",
            marginTop: 8,
          }}
        >
          {text}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Image
        style={styles.background}
        source={require("../../assets/images/water-background.png")}
      />

      <View style={{ width: "100%", flexDirection: "row", maxWidth: 820 }}>
        <View
          style={{
            flex: 0.5,
            alignItems: "center",
            justifyContent: "space-evenly",
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          {renderIcon(
            require("../../assets/images/marketing-cell-face.png"),
            "Your passengers\nconnect to Ship Wifi"
          )}
          {renderIcon(
            require("../../assets/images/marketing-cell-phone.png"),
            "Passengers use\ntheir own device",
            { marginTop: 20 }
          )}
        </View>
        {!isMobile && (
          <View style={{ flex: 1, alignItems: "center" }}>
            <Image
              style={styles.cellphoneImage}
              source={require("../../assets/images/marketing-cell-ui.png")}
            />
          </View>
        )}

        <View
          style={{
            flex: 0.5,
            alignItems: "center",
            justifyContent: "space-evenly",
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          {renderIcon(
            require("../../assets/images/marketing-cell-face.png"),
            "Integration w/Passengers\nManegement System"
          )}
          {renderIcon(
            require("../../assets/images/marketing-cell-phone.png"),
            "Cruise Lines get a branded app\nor integration with current app",
            { marginTop: 20 }
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: "center" },
  background: {
    position: "absolute",
    width: "100%",
    height: 350,
  },
  cellphoneImage: {
    marginTop: -50,
    width: 420,
    height: 400,
    resizeMode: "stretch",
  },
  icons: {
    width: 70,
    height: 70,
  },
});

export default NtCellphoneSection;
