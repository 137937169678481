import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";

function NtFeatureCodeItem({ code }) {
  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center", padding: 6 }}>
        <NtText style={styles.code}>{code.code}</NtText>
        <View style={{ marginLeft: 10 }}>
          <NtText style={styles.name}>{code.name}</NtText>
          <NtText style={[styles.details, { marginTop: 2 }]}>
            {code.details}
          </NtText>
        </View>
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  code: {
    backgroundColor: colors.blue,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: "white",
    fontSize: 14,
    fontWeight: "600",
  },
  name: {
    color: colors.darkest,
    fontWeight: "600",
    fontSize: 14,
  },
  details: {
    color: colors.lighGray,
    fontWeight: "400",
    fontSize: 14,
  },
});

export default NtFeatureCodeItem;
