import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtDevider from "../../components/NtDevider";

function NtMessageUserItem({ selected, item, onPress }) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? { backgroundColor: colors.blue, borderRadius: 6 } : {},
      ]}
      onPress={onPress}
    >
      <View style={{ paddingTop: 12, paddingBottom: 12 }}>
        <NtText style={[styles.name, selected ? { color: "white" } : {}]}>
          {item.name}
        </NtText>
        <NtText
          style={[
            styles.number,
            { marginTop: 4, fontWeight: "600" },
            selected ? { color: "white" } : {},
          ]}
        >
          {item.comments}
        </NtText>
        <NtText
          style={[
            styles.number,
            { marginTop: 4 },
            selected ? { color: "white" } : {},
          ]}
        >
          {item.number}
        </NtText>
      </View>
      {!selected && <NtDevider />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  name: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darkest,
  },
  number: {
    fontSize: 12,
    fontWeight: "500",
    color: colors.darker,
  },
});

export default NtMessageUserItem;
