import { headerize } from "jssip/lib/Utils";
import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";

function NtGuestAppFeatureSection(props) {
  const isMobile = useMobile();

  const renderItem = (image, title, description, containerStyle) => {
    return (
      <View style={[{ width: 200, alignItems: "center" }, containerStyle]}>
        <Image style={styles.image} source={image} />
        <NtText style={[styles.title, { marginTop: 25 }]}>{title}</NtText>
        <NtText style={[styles.description, { marginTop: 25 }]}>
          {description}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {renderItem(
          require("../../assets/images/marketing-guestapp-chat-feature-1.png"),
          "Guest-Cast",
          "Receive emergency broadcasts and notifications from the ship."
        )}

        {renderItem(
          require("../../assets/images/marketing-guestapp-chat-feature-2.png"),
          "Guest-Guide",
          "Passengers can type questions that are answered by an on-ship A.I. chat bot.",
          { marginTop: isMobile ? 40 : 0 }
        )}

        {renderItem(
          require("../../assets/images/marketing-guestapp-chat-feature-3.png"),
          "Guest-Friend",
          "Locate other guests or keep track of kids. Cruise Lines can target market passengers by location.",
          { marginTop: isMobile ? 40 : 0 }
        )}
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 60,
    height: 60,
    resizeMode: "contained",
  },
  title: {
    fontSize: 24,
    color: colors.lightBlue,
    fontWeight: "800",
  },
  description: {
    fontSize: 17,
    color: "white",
    fontWeight: "400",
    textAlign: "center",
  },
});

export default NtGuestAppFeatureSection;
