import clientnodered from "./clientnodered";
import settings from "../config/settings";
import client from "./client";
import Logger from "../common/utils/Logger";

export const fetchDirectory = async (domain) => {
  const response = await client.get(
    `/extension/domain/${domain}?page=${1}&pagesize=${10000}&extensionPrefix=9998`
  );

  if (response.ok) {
    response.data = response.data?.extensions?.map((each) => {
      return {
        id: each.extension_uuid,
        number: each.extension,
        name: each.outbound_caller_id_name || each.extension?.substring(4),
        comments: each.description,
        department: "office",
        externalNumber: "",
      };
    });
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
