import React from "react";
import { View, StyleSheet } from "react-native";
import beach from "../../assets/videos/601-2_601-7924_preview.mp4";
import ship from "../../assets/videos/623_623-0236_preview.mp4";
import video from "../../assets/videos/maritime-video-no-audio.mp4";
import ReactPlayer from "react-player";
import Logger from "../../common/utils/Logger";

function NtVideoSection({}) {
  return (
    <View style={styles.container}>
      <video
        playsInline
        loop
        muted
        autoPlay
        src={video}
        type="video/mp4"
        poster="https://images.nooz.ai/icons/category/maritime-video-thumbnail.jpg"
      />

      {/* <ReactPlayer
        loop={true}
        playing={true}
        muted={true}
        // url="https://www.youtube.com/watch?v=NvxGkAOHvQA"
        url={video}
        // playbackRate={0.03}
        width="100%"
        height={"100%"}
        // controls={true}
        onReady={() => {
          Logger("Video loaded and ready");
        }}
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtVideoSection;
