import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";
import NtText from "../../components/NtText";
import NotFoundController from "../../common/controllers/NoFoundController";

function NtGuestAppVoiceSection(props) {
  const isMobile = useMobile();

  const renderOption = (text, containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <View
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: colors.lightBlue,
          }}
        />
        <NtText style={[styles.text, { marginLeft: 15 }]}>{text}</NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Image
          style={[styles.image, isMobile ? { height: 250 } : { height: 460 }]}
          source={require("../../assets/images/marketing-guestapp-video.png")}
        />
        <View style={{ flex: 1, marginTop: isMobile ? 30 : 0 }}>
          <NtText style={styles.title}>Guest-Voice</NtText>
          <NtText style={[styles.subtitle, { marginTop: 30 }]}>
            Onboard / Ship to Ship / Ship to Shore
          </NtText>
          <NtText
            style={[styles.subtitle, { marginTop: 6, color: colors.lightBlue }]}
          >
            Passengers can receive calls with one or more of the following
            options:
          </NtText>

          {renderOption(
            "Loyalty Club passengers can have a dedicated phone number that can be used on all sailings",
            { marginTop: 35 }
          )}

          {renderOption("Passengers can purchase a dedicated phone number", {
            marginTop: 15,
          })}

          {renderOption(
            "Each ship has a dedicated number and each passenger can be reached at their 9-digit number",
            { marginTop: 15 }
          )}
        </View>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 300,
    height: 400,
    resizeMode: "contain",
  },
  title: {
    fontSize: 30,
    fontWeight: "800",
    color: "white",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "600",
    color: "white",
    textAlign: "center",
  },
  text: {
    fontSize: 20,
    fontWeight: "500",
    color: "white",
  },
});

export default NtGuestAppVoiceSection;
