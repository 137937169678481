import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtLoginButtonTypePicker from "./NtLoginButtonTypePicker";
import Logger from "../../common/utils/Logger";

function NtLoginButton({
  containerStyle,
  loading = false,
  onPress,
  onOptionPress,
  options,
  selected,
}) {
  const typesPickerRef = useRef(null);

  const renderTypesPickerModal = () => {
    return (
      <NtModal
        ref={typesPickerRef}
        renderBody={
          <NtLoginButtonTypePicker
            types={options}
            selected={selected}
            onPress={(selected) => {
              typesPickerRef?.current.dismiss();
              if (onOptionPress) {
                onOptionPress(selected);
              }
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <View
        style={[
          styles.login,
          { backgroundColor: onPress ? colors.blue : colors.lighGray },
        ]}
      >
        <NtText style={{ color: "white", fontSize: 14, fontWeight: "600" }}>
          Login using {selected?.title}
        </NtText>
      </View>

      <NtTouchableEffect
        style={[
          styles.type,
          {
            marginLeft: 2,
            backgroundColor: onPress ? colors.blue : colors.lighGray,
          },
        ]}
        onPress={() => {
          typesPickerRef?.current.show();
        }}
      >
        {!loading && <NtMaterialIcon name={selected?.icon} color="white" />}
        {loading && <NtActivityIndicator size="small" color="white" />}
      </NtTouchableEffect>

      {renderTypesPickerModal()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  login: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.lighGray,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 8,
  },
  type: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.lighGray,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: 8,
  },
});

export default NtLoginButton;
