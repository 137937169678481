import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";

function NtguestAppChatSection(props) {
  const renderIcon = (image, title) => {
    return (
      <View style={{ width: 100, alignItems: "center" }}>
        <Image style={styles.image} source={image} />
        <NtText style={[styles.text, { marginTop: 20, textAlign: "center" }]}>
          {title}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor="white">
      <NtText style={styles.title}>Guest-Chat</NtText>
      <NtText style={[styles.subtitle, { marginTop: 30 }]}>
        Onboard / Ship to Ship / Ship to Shore
      </NtText>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          marginTop: 50,
          justifyContent: "space-evenly",
        }}
      >
        {renderIcon(
          require("../../assets/images/marketing-guestapp-chat-image-ptt.png"),
          "Push-to-Talk Walkie Talkie"
        )}

        {renderIcon(
          require("../../assets/images/marketing-guestapp-chat-image-sms.png"),
          "SMS/MMS"
        )}

        {renderIcon(
          require("../../assets/images/marketing-guestapp-chat-image-lt.png"),
          "Language Translator"
        )}
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.darkBlue,
    textAlign: "center",
  },
  image: {
    width: 50,
    height: 80,
    resizeMode: "contained",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "600",
    color: colors.darkBlue,
    textAlign: "center",
  },
  text: {
    fontSize: 16,
    fontWeight: "600",
    color: colors.darkBlue,
  },
});

export default NtguestAppChatSection;
