import moment from "moment-timezone";
import { generateFullUser } from "../MessagingClient";

class IQItem {
  constructor(messageId, sender, address, data = null) {
    if (data) {
      this.mMessageId = data.messageid;
      this.mSender = data.sender;
      this.mAddress = data.address;
      this.mDate = moment
        .utc(data.date, "YYYY-MM-DD H:mm:ss z")
        .local()
        .toDate();
      this.mType = data.type;
      this.mStatus = data.status;
      this.mPayload = data.payload;
    } else {
      this.mMessageId = messageId;
      this.mSender = sender;
      this.mAddress = generateFullUser(address);
      this.mDate = new Date();
      this.mType = 0;
      this.mStatus = 0;
      this.mPayload = "IQ";
    }
  }

  setStatus = (status) => {
    this.mStatus = status;
  };

  setType = (type) => {
    this.mType = type;
  };

  getAddress = () => {
    return this.mAddress;
  };

  getData = () => {
    return {
      messageid: this.mMessageId,
      sender: this.mSender,
      address: this.mAddress,
      date: moment().utc().format("YYYY-MM-DD HH:mm:ss") + " GMT",
      type: this.mType,
      status: this.mStatus,
      payload: this.mPayload,
    };
  };
}

export default IQItem;
