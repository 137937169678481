import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import { FontAwesome } from "@expo/vector-icons";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtGatewayReportColumnItem({
  containerStyle,
  site,
  data,
  loading = false,
  maxGateway,
  total,
}) {
  const filterDataForGateway = (gateway) => {
    return data.filter((each) => each.description?.split(",")[0] == gateway);
  };

  const renderData = (count) => {
    return (
      <View
        style={{ padding: 10, alignItems: "center", justifyContent: "center" }}
      >
        <NtText>{count}</NtText>
      </View>
    );
  };

  const renderRow = (item, index) => {
    if (site.code == "Gateway") {
      return <View key={index}>{renderData(index + 1)}</View>;
    } else if (index + 1 > parseInt(maxGateway)) {
      return <View key={index}>{renderData("-")}</View>;
    }

    return (
      <View key={index}>
        {renderData(filterDataForGateway(index + 1)?.length)}
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {!loading && (
        <NtText
          style={{
            textAlign: "center",
            color: colors.darkest,
            fontWeight: "600",
          }}
        >
          {site.code}
        </NtText>
      )}
      {loading && <NtActivityIndicator size="small" color={colors.darkest} />}
      {Array.from(Array(parseInt(total))).map((each, index) =>
        renderRow(each, index)
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    padding: 10,
  },
});

export default NtGatewayReportColumnItem;
