import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtPartnerItem from "./NtPartnerItem";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";
import NtPartnerHAItem from "./NtPartnerHAItem";
import NtPartnerCarnivalukItem from "./NtPartnerCarnivalukItem";

function NtPartnersSection({}) {
  const isMobile = useMobile();

  const iconWidth = (isAH = false, altWidth = 0) => {
    return isMobile ? (isAH ? 160 : 180) : 220 + altWidth;
  };

  const iconHeight = (isAH = false, altHeight = 0) => {
    return isMobile ? (isAH ? 48 : 54) : 66 + altHeight;
  };

  const partnerHA = [
    {
      image: require("../../assets/images/partners/princess.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/holland.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/p-o-australia.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
    {
      image: require("../../assets/images/partners/seabourn.png"),
      width: iconWidth(true),
      height: iconHeight(true),
    },
  ];

  const partnerCarnivaluk = [
    {
      image: require("../../assets/images/partners/carnivaluk.png"),
      width: iconWidth(true, -60),
      height: iconHeight(true - 25),
    },
    {
      image: require("../../assets/images/partners/p-o-cruises.png"),
      width: iconWidth(true, -30),
      height: iconHeight(true, -15),
    },
    {
      image: require("../../assets/images/partners/cunard.png"),
      width: iconWidth(true, 30),
      height: iconHeight(true, 10),
    },
  ];

  const partners = [
    {
      image: require("../../assets/images/partners/ncl.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/disney.png"),
      width: iconWidth(false, 40),
      height: iconHeight(false, 18),
    },

    {
      image: require("../../assets/images/partners/crystal.png"),
      width: iconWidth(false, -20),
      height: iconHeight(false, -6),
    },
    {
      image: require("../../assets/images/partners/paradise.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/canadian-coast-guard.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/inmarsat.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/oracle.png"),
      width: iconWidth(),
      height: iconHeight(),
    },

    {
      image: require("../../assets/images/partners/tritan.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/bluetooth.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
    {
      image: require("../../assets/images/partners/suse.png"),
      width: iconWidth(),
      height: iconHeight(),
    },
  ];

  const renderRow = (item, index) => {
    return <NtPartnerItem item={item} />;
  };

  return (
    <NtSectionContainer backgroundColor={"white"}>
      <NtText style={[styles.title, { marginBottom: 30 }]}>Our Partners</NtText>
      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtPartnerHAItem items={partnerHA} />

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              alignContent: "center",
              justifyContent: "center",
              marginTop: 22,
            }}
          >
            <NtPartnerItem item={partners[0]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[2]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[4]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[6]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[8]} />
          </View>

          <View
            style={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <NtPartnerItem item={partners[1]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[3]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[5]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[7]} />
            <View style={{ height: 25 }} />
            <NtPartnerItem item={partners[9]} />
          </View>
        </View>

        <NtPartnerCarnivalukItem items={partnerCarnivaluk} />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 25,
    fontWeight: "700",
    color: colors.darkBlue,
  },
});

export default NtPartnersSection;
