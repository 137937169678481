import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import NtTextInput from "../../components/NtTextInput";
import TextareaAutosize from "react-textarea-autosize";
import NtButton from "../../components/NtButton";
import { useMobile } from "../../hooks/useMobile";
import { sendEmail } from "../../api/notification";
import Logger from "../../common/utils/Logger";

function NtInquirySection(props) {
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const isMobile = useMobile();

  const handleSend = async () => {
    setError(null);
    if (!name || !email || !phone || !company || !message) {
      setError("All Fields Requiered");
      return;
    }

    setSending(true);
    const body = `Contact information<br><br>Name: ${name}<br>Email: ${email}<br>Phone: ${phone}<br>Company: ${company}<br><br>Brief message:<br>${message}`;
    const response = await sendEmail(
      "nick@nettalkmaritime.com",
      "Maritime Inquiry Request",
      body
    );

    if (response.ok) {
      Logger("Successfully sent email.");
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setMessage("");
    }

    setSending(false);
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <NtText style={styles.title}>INQUIRY</NtText>
      <View
        style={{
          backgroundColor: "#151831",
          marginTop: 25,
          borderRadius: 8,
          padding: 25,
        }}
      >
        <NtText style={styles.subtitle}>
          If you are interested in getting more information about NT MARITIME,
          such as, how to get a quote, sign-up for the service, suggestions or
          questions, please submit our contact form below. We will respond
          promptly.
        </NtText>

        <View
          style={{
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
            marginTop: 25,
          }}
        >
          <View style={{ flex: 1 }}>
            <NtTextInput
              containerStyle={{
                backgroundColor: colors.darkBlue,
                borderRadius: 6,
              }}
              borderColor={colors.teal}
              placeholder={"Full Name"}
              icon={"account-circle-outline"}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <NtTextInput
              containerStyle={{
                marginTop: 20,
                borderRadius: 6,
                backgroundColor: colors.darkBlue,
              }}
              placeholder={"Phone Number"}
              icon={"phone-outline"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </View>

          {!isMobile && (
            <View
              style={{
                width: 1,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 8,
                marginBottom: 8,
                backgroundColor: "white",
              }}
            />
          )}

          <View style={{ flex: 1, marginTop: isMobile ? 20 : 0 }}>
            <NtTextInput
              containerStyle={{
                borderRadius: 6,
                backgroundColor: colors.darkBlue,
              }}
              placeholder={"Email"}
              icon={"email-outline"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <NtTextInput
              containerStyle={{
                marginTop: 20,
                borderRadius: 6,
                backgroundColor: colors.darkBlue,
              }}
              placeholder={"Company"}
              icon={"office-building"}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </View>
        </View>
        <TextareaAutosize
          style={{
            padding: 10,
            fontSize: 15,
            borderColor: colors.lighGray,
            marginTop: 20,
            borderRadius: 6,
            color: colors.lighGray,
            backgroundColor: colors.darkBlue,
            fontWeight: "400",
          }}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          minRows={6}
          placeholder="Message"
        />

        {error && (
          <NtText style={[styles.error, { marginTop: 6 }]}>{error}</NtText>
        )}

        <View>
          <NtButton
            containerStyle={{
              backgroundColor: colors.lightBlue,
              width: 120,
              marginTop: 25,
            }}
            isLoading={sending}
            text={"Send"}
            onPress={() => {
              handleSend();
            }}
          />
        </View>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: "white",
    fontSize: 20,
    fontWeight: "600",
  },
  subtitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "500",
  },
  error: {
    color: "red",
    fontSize: 13,
  },
});

export default NtInquirySection;
