import { headerize } from "jssip/lib/Utils";
import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";

function NtPBXMoreFeatureSection(props) {
  const isMobile = useMobile();

  const renderItem = (image, title, description, containerStyle) => {
    return (
      <View style={[{ width: 210, alignItems: "center" }, containerStyle]}>
        <Image style={styles.image} source={image} />
        <NtText style={[styles.title, { marginTop: 25 }]}>{title}</NtText>
        <NtText style={[styles.description, { marginTop: 25 }]}>
          {description}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <View
        style={{
          alignItems: isMobile ? "center" : "flex-start",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {renderItem(
          require("../../assets/images/marketing-crewapp-more-feature-1.png"),
          "Advanced Voicemail",
          "Create custom greetings, manage whith visual interface, receive audio files and text transcriptions by email, and enable PIN access"
        )}

        {renderItem(
          require("../../assets/images/marketing-crewapp-more-feature-2.png"),
          "Call Recordings",
          "Each extension can record calls for future playback and review",
          { marginTop: isMobile ? 40 : 0 }
        )}

        {renderItem(
          require("../../assets/images/marketing-crewapp-more-feature-3.png"),
          "Advanced Call Forwarding",
          "Create unique call forwarding rules for each extension that is unable to answer an incoming call. Can be configured based on status and office hours",
          { marginTop: isMobile ? 40 : 0 }
        )}
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 60,
    height: 60,
    resizeMode: "contained",
  },
  title: {
    fontSize: 20,
    color: colors.lightBlue,
    fontWeight: "800",
    textAlign: "center",
  },
  description: {
    fontSize: 17,
    color: "white",
    fontWeight: "400",
    textAlign: "center",
  },
});

export default NtPBXMoreFeatureSection;
