import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtBorderTextView from "../../components/NtBorderTextView";

function NtFileDownloadButton({ containerStyle, onPress, title, subtitle }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtMaterialIcon name="file-download-outline" color={colors.lighGray} />
      <View>
        <NtText style={[styles.title, { marginLeft: 6 }]}>{title}</NtText>
        <NtText style={[styles.title, { marginLeft: 6 }]}>{subtitle}</NtText>
      </View>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 6,
    borderColor: colors.lighGray,
    borderWidth: 1,
  },
  title: {
    color: colors.lighGray,
  },
});

export default NtFileDownloadButton;
