import React, { useContext, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtNavigationButton from "./NtNavigationButton";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import NtEnvironmentDropdown from "./NtEnvironmentDropdown";
import Logger from "../../common/utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NavigationBar({ sidebarCollapsed = false, onSidebarToggle }) {
  const context = useContext(UserContext);
  // const [selection, setSelection] = useState(navItems[0].route);
  const nav = useNavigation();
  const isMobile = useMobile();

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtTouchableEffect
          style={{ paddingLeft: 10, paddingRight: 10 }}
          onPress={onSidebarToggle}
        >
          <NtMaterialIcon
            name={
              sidebarCollapsed ? "chevron-double-right" : "chevron-double-left"
            }
            color={colors.blue}
            size={26}
          />
        </NtTouchableEffect>

        <NtTouchableEffect
          onPress={() => {
            nav.navigate(routes.marketing);
          }}
        >
          <Image
            style={styles.image}
            source={require("../../assets/images/nt-maritime-logo-blue.png")}
          />
        </NtTouchableEffect>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <NtEnvironmentDropdown containerStyle={{ marginRight: 30 }} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // width: "100%",
    // backgroundColor: colors.navigationColor,
    // elevation: 4,
    // flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "space-between",
    // paddingLeft: 30,
    // paddingRight: 30,
    // paddingTop: 8,
    // paddingBottom: 8,

    justifyContent: "space-between",
    backgroundColor: "white",
    flexDirection: "row",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.26,
    elevation: 1,
    marginBottom: 2,
    paddingTop: 6,
    paddingBottom: 6,
    height: 50,
  },
  image: {
    width: 130,
    height: 30,
    resizeMode: "contain",
  },
});

export default NavigationBar;
