import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Modal,
  Pressable,
  TouchableWithoutFeedback,
} from "react-native";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";
import Logger from "../common/utils/Logger";

const NtModal = React.forwardRef(({ renderBody, containerStyle }, ref) => {
  // function NtModal({ renderBody }) {
  const [visible, setVisible] = useState(false);

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
    dismiss: () => {
      setVisible(false);
    },
  }));

  return (
    <View style={styles.container}>
      <Modal
        style={{}}
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => {}}
      >
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <NtTouchableEffect
            activeOpacity={1}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            onPress={() => {
              setVisible(false);
            }}
          ></NtTouchableEffect>

          <View style={styles.centeredView}>
            <View style={[styles.modalView, containerStyle]}>{renderBody}</View>
          </View>
        </View>
      </Modal>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {},
  centeredView: {
    // flex: 1,

    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 4,
    shadowOpacity: 0.26,
    elevation: 1,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
  },
});

export default NtModal;
