import React from "react";
import { View, StyleSheet } from "react-native";
import NtChatRightItem from "./NtChatRightItem";
import NtChatLeftItem from "./NtChatLeftItem";

function NtChatItem({ item }) {
  if (item.mType == 0) {
    return <NtChatLeftItem item={item} />;
  } else {
    return <NtChatRightItem item={item} />;
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default NtChatItem;
