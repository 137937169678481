import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import { navigationRef } from "../../../App";
import routes from "../../navigation/routes";

function NtBackButton({ containerStyle }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        if (navigationRef?.current?.canGoBack()) {
          navigationRef?.current.goBack();
        } else {
          navigationRef?.current?.navigate(routes.dashboard);
        }
      }}
    >
      <NtMaterialIcon name={"arrow-left"} color={colors.lighGray} size={25} />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtBackButton;
