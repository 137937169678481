import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";

class BaseMaritimeController extends BaseController {
  state = {};

  getParentState() {
    return this.state;
  }
}

export default BaseMaritimeController;
