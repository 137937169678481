import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtButton from "../../components/NtButton";
import { useMobile } from "../../hooks/useMobile";

function NtStarlinkSection({ onLearnMore, onRequestInfo }) {
  const isMobile = useMobile();

  const renderSpecs = (image, text, subtext, containerStyle) => {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            justifyContent: "space-between",
          },
          containerStyle,
        ]}
      >
        <Image style={[styles.icons, { marginRight: 6 }]} source={image} />
        <View style={{ flex: 1 }}>
          <NtText
            style={[
              styles.header,
              { marginTop: 3, fontSize: 20, fontWeight: "600", color: "white" },
            ]}
          >
            {text}
          </NtText>
          <NtText
            style={[
              styles.text,
              {
                marginTop: 2,
                fontSize: 14,
                fontWeight: "400",
                color: "#2EB4D0",
              },
            ]}
          >
            {subtext}
          </NtText>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Image
        style={styles.background}
        source={require("../../assets/images/starlink-bg-faded.png")}
      />

      <View style={{ width: "100%", flexDirection: "row", maxWidth: 820 }}>
        <View
          style={[
            { width: "75%", maxWidth: 720, paddingLeft: 20, paddingBottom: 30 },
          ]}
        >
          <NtText
            style={[
              { marginTop: 60, color: "white", fontSize: 28, fontWeight: 700 },
            ]}
          >
            Starlink Maritime
          </NtText>
          <NtText style={[{ marginTop: 20, color: "white", fontSize: 20 }]}>
            High-speed, low-latency internet connectivity, in the most remote
            waters across the world, for your guests and operations.
          </NtText>
          <NtText style={[{ marginTop: 20, color: "#73A7C6", fontSize: 15 }]}>
            NT Maritime offers end-to-end solutions ranging from installation to
            network setup and support, along with comprehensive account
            management services.
          </NtText>

          <View
            style={{
              marginTop: 30,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <NtButton
              containerStyle={{ width: 110 }}
              text={"Request Info"}
              onPress={onRequestInfo}
            />
            {/* <NtButton
                        containerStyle={{ marginLeft:20, width: 110, backgroundColor: "transparent", borderColor: "white", borderWidth: 1,}}
                        text={"Learn More"}
                        onPress={onLearnMore}
                    /> */}
          </View>
        </View>
      </View>

      <Image
        style={[styles.starlinkImage, { marginTop: isMobile ? 100 : 110 }]}
        source={require("../../assets/images/starlink-hardware-only.png")}
      />

      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 920,
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: isMobile ? "column" : "row",
          paddingTop: 15,
          marginTop: isMobile ? 0 : -10,
          marginBottom: 50,
          // borderWidth:1, borderColor: "yellow",
        }}
      >
        {renderSpecs(
          require("../../assets/images/icon-starlink-download.png"),
          "Up to 220 Mbps",
          "Download Speed"
        )}

        {renderSpecs(
          require("../../assets/images/icon-starlink-upload.png"),
          "Up to 40 Mbps",
          "Upload Speed",
          { marginTop: isMobile ? 40 : 0 }
        )}
        {renderSpecs(
          require("../../assets/images/icon-starlink-latency.png"),
          "less than 99ms",
          "Latency",
          { marginTop: isMobile ? 40 : 0 }
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    paddingBottom: 30,
    backgroundColor: "#183052",
    width: "100%",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: 500,
  },
  textcopy: {},
  starlinkImage: {
    marginTop: 120,
    width: 530,
    height: 172,
    resizeMode: "contain",
    //backgroundColor: "#183052",
  },
  icons: {
    width: 50,
    height: 50,
  },
});

export default NtStarlinkSection;
