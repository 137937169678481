import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtRootPageContainerHeader({
  title,
  subtitle,
  containerStyle,
  titleSize = 35,
  subtitleSize = 18,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.text, { fontSize: titleSize }]}>{title}</NtText>
      {subtitle && (
        <NtText style={[styles.text, { fontSize: subtitleSize }]}>
          {subtitle}
        </NtText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 42,
    fontWeight: "600",

    color: colors.lighGray,
  },
});

export default NtRootPageContainerHeader;
