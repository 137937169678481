import React, { useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import UserContext from "../../context/UserContext";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtDisclosure from "../../components/NtDisclosure";
import NtModal from "../../components/NtModal";
import NtEnvironmentPicker from "./NtEnvironmentPicker";
import environmentsdata from "../data/environmentsdata";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtEnvironmentDropdown({ containerStyle }) {
  const context = useContext(UserContext);
  const environmentPickerRef = useRef(null);

  const renderEnvoronmentPickerModal = () => {
    return (
      <NtModal
        ref={environmentPickerRef}
        renderBody={
          <NtEnvironmentPicker
            onPress={(location) => {
              environmentPickerRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        environmentPickerRef?.current?.show();
      }}
    >
      <NtText style={styles.title}>{context?.user?.environment?.name}</NtText>
      <NtMaterialIcon
        containerStyle={{ marginLeft: 5 }}
        name="chevron-down"
        color={colors.almostBlack}
      />
      {renderEnvoronmentPickerModal()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: colors.almostBlack,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 6,
    paddingLeft: 8,
    borderRadius: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.almostBlack,
  },
});

export default NtEnvironmentDropdown;
