import React, { useState } from "react";
import { View, StyleSheet, Image, Dimensions } from "react-native";
import Logger from "../../common/utils/Logger";

function NtPBXHeaderSection({}) {
  const [imgHeight, setImgHeight] = useState(0);

  return (
    <View
      onLayout={(event) => {
        Image.getSize(
          require("../../assets/images/marketing-pbx-header-background.jpeg"),
          (width, height) => {
            const win = Dimensions.get("window");
            const ratio = win.width / width;
            setImgHeight(height * ratio);
          }
        );
      }}
      style={styles.container}
    >
      <Image
        style={[styles.background, { height: imgHeight }]}
        source={require("../../assets/images/marketing-pbx-header-background.jpeg")}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  background: {
    width: "100%",
  },
});

export default NtPBXHeaderSection;
