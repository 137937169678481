import { create } from "apisauce";
import settings from "../config/settings";

const client = create({
  baseURL: global.apiUrl,
  headers: { Accept: "application/json" },
});

client.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/hal+json";
  request.headers["Authorization"] = `Basic ${global.apiAuth}`;
});

export const parseQueryParams = (parameters) => {
  let query = "";
  if (parameters) {
    query += "?";
    const paramsKeys = Object.keys(parameters);
    paramsKeys.forEach((key) => {
      query += `${key}=${parameters[key]}&`;
    });
    query = query.slice(0, -1);
  }
  return query;
};

export default client;
