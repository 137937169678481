import React from "react";
import { View, StyleSheet } from "react-native";
import video from "../../../assets/videos/hospitalitypbx-video-no-audio.mp4";
import ReactPlayer from "react-player";

function NtHospitalityVideoSection({}) {
  return (
    <View style={styles.container}>
      <video
        playsInline
        loop
        muted
        autoPlay
        src={video}
        type="video/mp4"
        poster="https://images.nooz.ai/icons/category/maritime-video-thumbnail.jpg"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtHospitalityVideoSection;
