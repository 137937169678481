import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtHospitalityVideoSection from "../components/hospitalitypbx/NtHospitalityVideoSection";
import NtHeader from "../components/NtHeader";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtProducSection from "../components/hospitalitypbx/NtProducSection";
import NtFeatureSection from "../components/hospitalitypbx/NtFeatureSection";
import NtCoreFeatureSection from "../components/hospitalitypbx/NtCoreFeatureSection";
import NtBenefitsSection from "../components/hospitalitypbx/NtBenefitsSection";
import Logger from "../../common/utils/Logger";

class MarketingHospitalityPBXController extends React.Component {
  state = {};
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHospitalityVideoSection />
          <NtProducSection />
          <NtBenefitsSection
            onDownloadCaseStudy={() => {
              Logger("Hapening inside");
            }}
          />
          <NtFeatureSection />
          <NtCoreFeatureSection />
          <NtFooterBar />
          <NtHeader />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingHospitalityPBXController;
