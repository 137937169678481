import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";

function NtHealthOutbreakSection(props) {
  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <View style={{ flex: 1 }}>
          <NtText style={styles.header}>
            Stop outbreaks before they can spread.
          </NtText>
          <NtText style={[styles.text, { marginTop: 10 }]}>
            Cruise lines have the new priority of providing reassurance that
            their cruise will be a healthy experience. NT Maritime’s new
            solution integrates innovative technologies that improve health
            monitoring and contact tracing onboard cruise ships. The system
            remotely gathers and provides the medical team with a wealth of
            critical information that is immediately actionable.
          </NtText>
        </View>
        <Image
          style={[styles.image, { marginLeft: 20 }]}
          source={require("../../assets/images/marketing-health-crus.png")}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 50,
        }}
      >
        <Image
          style={[styles.image, { marginRight: 20 }]}
          source={require("../../assets/images/marketing-health-retroscope.png")}
        />
        <View style={{ flex: 1 }}>
          <NtText style={styles.header}>
            Stop outbreaks before they can spread.
          </NtText>
          <NtText style={[styles.text, { marginTop: 10 }]}>
            Cruise lines have the new priority of providing reassurance that
            their cruise will be a healthy experience. NT Maritime’s new
            solution integrates innovative technologies that improve health
            monitoring and contact tracing onboard cruise ships. The system
            remotely gathers and provides the medical team with a wealth of
            critical information that is immediately actionable.
          </NtText>
        </View>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  header: {
    color: colors.lightBlue,
    fontSize: 23,
  },
  text: {
    color: "white",
    fontSize: 16,
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: "contain",
  },
});

export default NtHealthOutbreakSection;
