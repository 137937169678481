import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtAddonSection from "../components/NtAddonSection";
import NtStarlinkSection from "../components/NtStarlinkSection";
import NtCellphoneSection from "../components/NtCellphoneSection";
import NtCommunicationSection from "../components/NtCommunicationSection";
import NtInquirySection from "../components/NtInquirySection";
import NtLatestNewsSection from "../components/NtLatestNewsSection";
import NtMaritimeSection from "../components/NtMaritimeSection";
import NtPartnersSection from "../components/NtPartnersSection";
import NtVideoSection from "../components/NtVideoSection";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtHeader from "../components/NtHeader";
import routes from "../../navigation/routes";
import NtButton from "../../components/NtButton";
import Logger from "../../common/utils/Logger";
import NtBenefitsSection from "../components/hospitalitypbx/NtBenefitsSection";

class MarketingController extends React.Component {
  starlinkRef = React.createRef();
  inQueryRef = React.createRef();

  state = {
    path: "",
  };

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );
  }

  handleFocus = () => {
    if (
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.contactus
    ) {
      Logger("Contact us enabled.... will scroll to section..");
      this.props.navigation.setParams({ contactus: false });
      setTimeout(() => {
        this.inQueryRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }

    /**
     * Remove from here once the starlink page is completed.
     */
    if (this.props.route.name && this.props.route.name == routes.starlink) {
      this.starlinkRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtVideoSection />

          <NtMaritimeSection />
          <NtCellphoneSection />
          <NtCommunicationSection />

          <NtBenefitsSection
            title="Integrated Hotel & Guest Communication Experience"
            onLearnMore={() => {
              this.props.navigation.navigate(routes.hospitalitypbx);
            }}
          />

          <div ref={this.starlinkRef}>
            <NtStarlinkSection
              onLearnMore={() => {
                this.props.navigation.navigate(routes.starlink);
              }}
              onRequestInfo={() => {
                this.inQueryRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </div>

          {/* <NtAddonSection
            onLearnMore={() => {
              this.props.navigation.navigate(routes.health);
            }}
          /> */}

          <div ref={this.inQueryRef}>
            <NtInquirySection />
          </div>

          <NtLatestNewsSection />
          <NtPartnersSection />
          <NtFooterBar
            onContactUs={() => {
              setTimeout(() => {
                this.handleFocus();
              }, 300);
            }}
          />
          <NtHeader />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingController;
