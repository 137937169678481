import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import { useMobile } from "../../../hooks/useMobile";

function NtCoreFeatureSection({}) {
  const isMobile = useMobile();
  return (
    <View style={styles.container}>
      <NtText
        style={{
          fontSize: 22,
          fontWeight: "700",
          color: "white",
          textAlign: "center",
          marginTop: 40,
        }}
      >
        CORE FEATURES
      </NtText>
      <NtText
        style={{
          fontSize: 17,
          fontWeight: "300",
          color: "white",
          textAlign: "center",
          marginTop: 4,
        }}
      >
        HOTELMATE
      </NtText>

      <View style={{ marginTop: 25, alignItems: "center" }}>
        <View
          style={{
            position: "absolute",
            backgroundColor: colors.darkBlue,

            left: 0,
            width: "100%",
            height: "30%",
          }}
        />
        <View
          style={{
            position: "absolute",
            backgroundColor: "#2d9cff",

            left: 0,
            top: "30%",

            width: "100%",
            height: "40%",
          }}
        />
        <View
          style={{
            position: "absolute",
            backgroundColor: colors.darkBlue,

            left: 0,
            top: "70%",

            width: "100%",
            height: "30%",
          }}
        />

        <View
          style={[
            {
              alignItems: "center",
              justifyContent: "space-evenly",
            },
            isMobile ? { flexDirection: "column" } : { flexDirection: "row" },
          ]}
        >
          <Image
            style={[styles.image]}
            source={require("../../../assets/images/marketing-hospitality-corefeatures-phone-a.png")}
          />
          <Image
            style={[styles.image]}
            source={require("../../../assets/images/marketing-hospitality-corefeatures-phone-b.png")}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
  },
  image: {
    width: 380,
    height: 380,
    resizeMode: "contain",
  },
});

export default NtCoreFeatureSection;
