import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../../components/NtText";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import colors from "../../../config/colors";
import { useMobile } from "../../../hooks/useMobile";

function NtFeatureSection({}) {
  const isMobile = useMobile();

  const renderMiniSection = (icon, title) => {
    return (
      <View
        style={[
          { flexDirection: "row", alignItems: "center" },
          isMobile ? { marginBottom: 10 } : {},
        ]}
      >
        <NtMaterialIcon size={26} name={icon} color={colors.lightest} />
        <NtText
          style={{
            fontSize: 18,
            fontWeight: "300",
            color: colors.lightest,
            marginLeft: 25,
          }}
        >
          {title}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText
        style={{
          textAlign: "center",
          fontSize: 22,
          fontWeight: "700",
          color: "white",
          marginTop: 30,
        }}
      >
        FEATURES
      </NtText>

      <View
        style={[
          {
            justifyContent: "center",
            marginTop: 20,
          },
          isMobile
            ? { flexDirection: "column", alignItems: "center" }
            : { flexDirection: "row" },
        ]}
      >
        <View style={{ justifyContent: "space-evenly" }}>
          {renderMiniSection("signal", "Easily Scalable")}
          {renderMiniSection("tools", "Easy to Maintain")}
          {renderMiniSection("arrow-up", "Easy to Launch")}
          {renderMiniSection("video", "Video Calling")}
          {renderMiniSection("cellphone", "Walkie Talkie")}
          {renderMiniSection("message-outline", "Messaging")}
        </View>
        <Image
          style={[styles.image, isMobile ? { marginTop: 15 } : {}]}
          source={require("../../../assets/images/marketing-hospitality-features-woman.png")}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#2d9cff",
  },
  image: {
    width: 320,
    height: 320,
    resizeMode: "contain",
  },
});

export default NtFeatureSection;
