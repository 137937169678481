import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtLatestNewsCard from "./NtLatestNewsCard";
import NtSectionContainer from "./NtSectionContainer";
import Swiper from "react-native-web-swiper";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { useMobile } from "../../hooks/useMobile";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtLatestNewsSection({}) {
  const swipeRef = React.createRef();
  const isMobile = useMobile();
  const news = [
    {
      image: require("../../assets/images/marketing-latestnews-1.png"),
      source: "Sea News",
      date: "August 4, 2021",
      description:
        "Exclusive Interview with netTALK Maritime COO Nicholas Kyriakides",
      link: "https://seanews.co.uk/shipping-news/exclusive-interview-with-nettalk-maritime-coo-nicholas-kyriakides/",
    },
    {
      image: require("../../assets/images/marketing-latestnews-2.png"),
      source: "Cruise & Ferry Review",
      date: "April 6, 2021",
      description:
        "Embarking on innovation. We take a look at some of the solutions that are making passenger ships safer and connected than ever before",
      link: "https://www.cruiseandferry.net/digital/magazine/2021/spring/120/index.html",
    },
    {
      image: require("../../assets/images/marketing-latestnews-3.png"),
      source: "Seatrade Cruise News",
      date: "March 25, 2021",
      description:
        "netTALK Maritime partners with Oracle Hospitality to engance onboard communications",
      link: "https://www.seatrade-cruise.com/information-technology/nettalk-maritime-partners-oracle-hospitality-enhance-board-communications",
    },
    {
      image: require("../../assets/images/marketing-latestnews-4.png"),
      source: "Hospitality Tech",
      date: "December 3, 2020",
      description:
        "Wearable Tech: The Cure for the Cruise Industry? By Nicholas Kyriakides, COO of netTALK Maritime",
      link: "https://hospitalitytech.com/wearable-tech-cure-cruise-industry",
    },
    {
      image: require("../../assets/images/marketing-latestnews-5.png"),
      source: "Cruise Industry News",
      date: "September 9, 2020",
      description:
        "netTALK maritime published in Cruise Industry News - Return to Service special edition",
      link: "https://www.cruiseindustrynewswire.com/pdf/wp-content/uploads/2020/09/CIN2020_RTS.pdf",
    },
    {
      image: require("../../assets/images/marketing-latestnews-6.png"),
      source: "Cruise & Ferry",
      date: "July 10, 2020",
      description:
        "netTALK Maritime integrates Tritan Software SeaCare and SeaConsult",
      link: "https://www.cruiseandferry.net/articles/nettalk-maritime-integrates-tritan-software-seacare-and-seaconsult-1",
    },
    {
      image: require("../../assets/images/marketing-latestnews-7.png"),
      source: "Riviera Maritime Media",
      date: "July 1, 2020",
      description:
        "Trackling coronavirus with health screening and communications",
      link: "https://www.rivieramm.com/news-content-hub/news-content-hub/combine-health-screening-and-communications-to-tackle-coronavirus-infection-60045",
    },
  ];

  const renderChevron = (chevron, onPress) => {
    if (isMobile) {
      return null;
    }
    return (
      <NtTouchableEffect onPress={onPress}>
        <NtMaterialIcon name={chevron} color={colors.lightBlue} size={50} />
      </NtTouchableEffect>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.lightest}>
      <NtText style={styles.header}>Latest News</NtText>

      <View
        style={{
          marginTop: 30,
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {renderChevron("chevron-left", () => {
          swipeRef.current.goToPrev();
        })}

        <View style={{ flex: 1, height: 430 }}>
          <Swiper
            ref={swipeRef}
            innerContainerStyle={{}}
            containerStyle={{}}
            slideWrapperStyle={{}}
            from={1}
            loop={true}
            timeout={6}
            controlsProps={{
              prevTitle: "",
              nextTitle: "",
              dotsTouchable: true,
            }}
          >
            {news.map((each) => (
              <NtLatestNewsCard
                key={each.toString()}
                item={each}
                onPress={(item) => {
                  window.open(item.link);
                }}
              />
            ))}
          </Swiper>
        </View>
        {renderChevron("chevron-right", () => {
          swipeRef.current.goToNext();
        })}
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  header: {
    color: colors.darkest,
    fontSize: 23,
    fontWeight: "600",
    textAlign: "center",
  },
});

export default NtLatestNewsSection;
