import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtMessageChatInputSendButton({
  containerStyle,
  enabled = false,
  onPress,
  loading = false,
}) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        enabled
          ? { backgroundColor: colors.blue }
          : { backgroundColor: colors.lighGray },
        containerStyle,
      ]}
      onPress={() => {
        if (onPress && enabled) {
          onPress();
        }
      }}
    >
      <NtText
        style={{
          fontSize: 13,
          fontWeight: "600",
          color: "white",
        }}
      >
        Send
      </NtText>
      {loading && (
        <NtActivityIndicator
          containerStyle={{ marginLeft: 6 }}
          size={15}
          color="white"
        />
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 15,
    marginTop: 6,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default NtMessageChatInputSendButton;
