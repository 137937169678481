import React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import NtTextInput from "./NtTextInput";
import NtTouchableEffect from "./NtTouchableEffect";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";
import NtText from "./NtText";

function NtSearchBar({ containerStyle, ...otherProps }) {
  const renderClearButton = () => {
    if (!otherProps?.value) {
      return null;
    }

    return (
      <NtTouchableEffect
        style={{
          backgroundColor: colors.lighGray,
          height: 23,
          width: 23,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 18,
        }}
        onPress={() => {
          if (otherProps.onChangeText) {
            otherProps.onChangeText("");
          }
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "bold", color: "white" }}>
          x
        </NtText>
      </NtTouchableEffect>
    );
  };
  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon name="magnify" color={colors.lighGray} size={24} />
      <TextInput style={[styles.input]} placeholder="Search" {...otherProps} />
      {renderClearButton()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: colors.lighestGray,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 20,
  },
  input: {
    marginRight: 10,
    marginLeft: 10,
    flex: 1,
    paddingVertical: 0,
    outlineColor: colors.lighter,
    outlineStyle: "none",
  },
});

export default NtSearchBar;
