import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtHeader from "../components/NtHeader";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtCrewAppFeatureSection from "../components/NtCrewAppFeatureSection";
import NtCrewAppMoreFeatureSection from "../components/NtCrewAppMoreFeatureSection";
import NtCrewAppHeaderSection from "../components/NtCrewAppHeaderSection";

class MarketingCrewAppController extends BaseController {
  state = {};
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHeader
            containerStyle={{ position: "relative" }}
            hoverEnabled={false}
          />
          <NtCrewAppHeaderSection />
          <NtCrewAppFeatureSection />
          <NtCrewAppMoreFeatureSection />
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingCrewAppController;
