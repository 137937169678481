export const linking = {
  config: {
    screens: {
      marketing: "",
      health: "/health",
      guestapp: "/guestapp",
      crewapp: "/crewapp",
      pbxsystem: "/pbxsystem",
      hospitalitypbx: "/hospitalitypbx",
      auth: "/auth",
      authcode: "/authcode/:extension",
      settings: "/settings",
      systemmessage: "/systemmessage",
      messagev2: "/messagev2",
      dialpad: "/dialpad",
      dashboard: "/dashboard",
      voicemail: "/voicemail",
      vvn: "/vvn",
      starlink: "/starlink",
      docs: "/docs",

      //vvn
      pinmanagement: "/pinmanagement",

      //ha
      upload: "/upload",
      extensioncreate: "/extensioncreate",
      extensionreport: "/extensionreport",
      gatewayreport: "/gatewayreport",
      appaccess: "/appaccess",
      links: "/links",
      featurecode: "/featurecode",
      gateway: "/gateway/:gateway",

      notfound: "*",
    },
  },
};

const routes = {
  marketing: "marketing",
  health: "health",
  guestapp: "guestapp",
  crewapp: "crewapp",
  pbxsystem: "pbxsystem",
  hospitalitypbx: "hospitalitypbx",
  auth: "auth",
  authcode: "authcode",
  settings: "settings",
  systemmessage: "systemmessage",
  messagev2: "messagev2",
  dialpad: "dialpad",
  dashboard: "dashboard",
  voicemail: "voicemail",
  vvn: "vvn",
  starlink: "starlink",
  docs: "docs",

  //vvn
  pinmanagement: "pinmanagement",

  //ha
  upload: "upload",
  extensioncreate: "extensioncreate",
  extensionreport: "extensionreport",
  gatewayreport: "gatewayreport",
  appaccess: "appaccess",
  links: "links",
  featurecode: "featurecode",
  gateway: "gateway",

  notFound: "notfound",
};

export default routes;
