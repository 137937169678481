import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";

class BaseAuthController extends BaseController {
  state = {};

  parseParams = (extension) => {
    const params = {
      username: extension?.extension,
      password: extension?.password,
      voipservice: extension?.accountcode,
      msgservice: extension?.accountcode,
      department: "",
      isadmin: false,
      name:
        extension?.effective_caller_id_name ||
        extension?.extension?.substring(4),
    };

    return params;
  };
}

export default BaseAuthController;
