import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";

function NtMessageChatInputTemplates({ onPress }) {
  const [open, setOpen] = useState(false);

  const templates = [
    {
      title: "Hello",
    },
    {
      title: "Good Morning!",
    },
    {
      title: "How can we help?",
    },
    {
      title: "Can you explain the situation you're facing?",
    },
    {
      title: "What is the current issue?",
    },
  ];

  const renderRow = (item) => {
    return (
      <NtTouchableEffect
        key={item.title}
        style={{
          borderRadius: 20,
          borderWidth: 1,
          borderColor: colors.lighestGray,
          marginRight: 10,
          marginBottom: 10,
        }}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
      >
        <NtText
          style={{
            marginLeft: 8,
            marginRight: 8,
            marginTop: 3,
            marginBottom: 3,
            color: colors.lighGray,
            flex: 1,
          }}
        >
          {item.title}
        </NtText>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <NtTouchableEffect
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
          onPress={() => {
            setOpen(!open);
          }}
        >
          <NtMaterialIcon
            name={open ? "chevron-down" : "chevron-up"}
            color={colors.lighGray}
            size={28}
          />
          {!open && (
            <NtText
              style={{
                color: colors.lighGray,
                marginTop: -6,
                fontWeight: "600",
                fontSize: 12,
              }}
            >
              Templates
            </NtText>
          )}
        </NtTouchableEffect>
      </View>

      {open && (
        <View style={{}}>
          <NtText>{templates.map((each) => renderRow(each))}</NtText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default NtMessageChatInputTemplates;
