import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { createExtension } from "../../api/extension";
import { sleep } from "../../common/utils/ControllerUtils";

export const handleExtensionCreate = async (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        const success = [];
        const failure = [];

        results.data.map(async (each, index) => {
          const response = await createExtension(
            each.domain,
            each.extension,
            each.password,
            each.phone,
            each.name,
            each.description,
            each.title,
            each.location
          );

          //slowing down the request
          await sleep(500);

          if (response.ok) {
            success.push(each);
          } else {
            failure.push({
              extension: each,
              status: response.status,
              error: response.data,
            });
          }

          Logger("---- processing ", success.length, failure.length);

          if (success.length + failure.length == results.data.length) {
            resolve({
              ok: true,
              success: success,
              failure: failure,
              total: results.data.length,
            });
          }
        });
      },
    });
  });
};

export const handleFailureFile = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};

export const handleCSVDownloadData = async (data, columns) => {
  return new Promise((resolve, reject) => {
    const handleColumnData = (item, column) => {
      if (column == "gateway") {
        if (item.description?.match(/\d+,\d+,\d+,\d+/)) {
          return item.description.split(",")[0];
        }
        return "";
      } else if (column == "port") {
        if (item.description?.match(/\d+,\d+,\d+,\d+/)) {
          return item.description.split(",")[1];
        }
        return "";
      } else if (column == "extension") {
        return item[column]?.substring(4);
      } else if ((column = "effective_caller_id_name")) {
        if (!item[column]) {
          item[column] = item["extension"]?.substring(4);
        }
      }
      return item[column];
    };

    let csvData = "";

    //first... will put all the column names...
    columns.map((each) => {
      csvData += `${each.title},`;
    });
    csvData = csvData.substring(0, csvData.length - 1);

    //next... will start adding the data.....

    data?.map((item) => {
      let row = "";
      columns.map((eachColumn) => {
        row += `${handleColumnData(item, eachColumn.name)},`;
      });
      row = row.substring(0, row.length - 1);
      csvData += `\n${row}`;
    });

    resolve({ ok: true, data: csvData });
  });
};
