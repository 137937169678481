import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";

function NtPBXTextSection(props) {
  return (
    <NtSectionContainer>
      <View style={{ paddingTop: 15, paddingBottom: 15 }}>
        <NtText style={styles.text}>
          Provides the ability to{" "}
          <NtText style={[styles.text, { color: colors.lightBlue }]}>
            create
          </NtText>{" "}
          a phone directory for cruise ships and shoreside operations for
          seamless{" "}
          <NtText style={[styles.text, { color: colors.lightBlue }]}>
            worldwide communication.
          </NtText>
        </NtText>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.darkBlue,
  },
});

export default NtPBXTextSection;
