import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "./NtMaterialIcon";
import NtText from "./NtText";
import colors from "../config/colors";

function NtUnderConstruction({ containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon
        name="google-downasaur"
        color={colors.lighter}
        size={30}
      />
      <NtText style={[styles.text, { marginLeft: 10 }]}>
        Under Construction
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 22,
    fontWeight: "600",
    color: colors.lighter,
  },
});

export default NtUnderConstruction;
