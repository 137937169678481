import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";
import NtText from "../../components/NtText";
import NotFoundController from "../../common/controllers/NoFoundController";

function NtPBXFeatureSection(props) {
  const isMobile = useMobile();

  const renderOption = (title, subtitle, containerStyle) => {
    return (
      <View>
        <View
          style={[
            { flexDirection: "row", alignItems: "center" },
            containerStyle,
          ]}
        >
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: colors.lightBlue,
            }}
          />
          <NtText style={[styles.subtitle, { marginLeft: 15 }]}>{title}</NtText>
        </View>
        <NtText style={[styles.text, { marginTop: 8 }]}>{subtitle}</NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <NtText style={styles.title}>FEATURES</NtText>
      <NtText style={[styles.subtitle, { fontSize: 18, marginTop: 20 }]}>
        NT Maritime can work in harmony with the existing PBX or function as the
        full PBX on board ships with the same integration to the shoreside
        personel
      </NtText>
      <View
        style={{
          marginTop: 30,
          width: "100%",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Image
          style={[
            styles.image,
            isMobile ? { height: 250, marginTop: 30 } : { height: 460 },
          ]}
          source={require("../../assets/images/marketing-pbx-feature-phone.png")}
        />
        <View style={{ flex: 1, marginTop: isMobile ? 30 : 0 }}>
          {renderOption(
            "Virtual Receptionist",
            "Create auto attendants that answer calls and present callers with a menu of prompts to help them find the right person to speck to.",
            { marginTop: 25 }
          )}

          {renderOption(
            "Conference Bridge",
            "Create multiple conference rooms and conduct meetings simultaneously. Features include PIN access, PIN bypass with invitation, and more.",
            { marginTop: 20 }
          )}

          {renderOption(
            "Extensions",
            "An extension for every phone in each stateroom, store, restaurant, office, or department on the cruise ship.",
            { marginTop: 20 }
          )}

          {renderOption(
            "Mobile App",
            "Work from anywhere land or sea. Make and receive calls, join conference calls, check voicemail, and more.",
            { marginTop: 20 }
          )}
        </View>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 300,
    height: 400,
    resizeMode: "contain",
  },
  title: {
    fontSize: 35,
    fontWeight: "800",
    color: colors.lightBlue,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 22,
    fontWeight: "700",
    color: "white",
    textAlign: "center",
  },
  text: {
    fontSize: 18,
    fontWeight: "500",
    color: "white",
  },
});

export default NtPBXFeatureSection;
