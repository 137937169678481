import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import { createExtension } from "../../api/extension";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import { showToast } from "../../common/utils/ControllerUtils";
import BaseController from "../../common/base/BaseController";
import NtTextInput from "../../components/NtTextInput";
import NtFormButtonNext from "../../components/NtFormButtonNext";

class CreateExtensionController extends BaseController {
  domainModalRef = React.createRef();
  extensionCreateFormRef = React.createRef();

  state = {
    loading: false,

    phone: "",
    extension: "",
    password: "",
    name: "",
    description: "",
    title: "",
    location: "",
  };

  componentDidMount() {}

  handleExtensionCreate = async () => {
    if (
      !this.state.extension &&
      !this.state.password &&
      !this.state.name &&
      !this.state.description
    ) {
      showToast("Oops...", "Please fill all the * fields.", "error");
      return;
    }

    this.setState({ loading: true });
    const response = await createExtension(
      this.context.user?.environment?.domain,
      this.state.extension,
      this.state.password,
      this.state.phone,
      this.state.name,
      this.state.description,
      this.state.title,
      this.state.location
    );

    if (response.ok) {
      this.setState({
        extension: "",
        password: "",
        phone: "",
        name: "",
        description: "",
        title: "",
        location: "",
      });
      showToast("Success", "Successfully created the extension");
    } else {
      showToast(
        "Oops...",
        "Unable to create the extension code " + response.status,
        "error"
      );
    }

    this.setState({ loading: false });
  };

  renderInput = (
    title,
    placeholder,
    value,
    setValue,
    containerStyle,
    titleStyle
  ) => {
    return (
      <View style={[{ flex: 1 }, containerStyle]}>
        <NtText
          style={[
            styles.inputTitle,
            { marginLeft: 5, marginBottom: 4 },
            titleStyle,
          ]}
        >
          {title}
        </NtText>
        <NtTextInput
          textStyle={{ flex: 1 }}
          containerStyle={[styles.input]}
          placeholder={placeholder}
          placeholderTextColor={colors.lighGray}
          value={value}
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={(text) => setValue(text)}
        />
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Create Extensions"}
        showFooter={true}
        showBackButton={true}
      >
        <View>
          <View
            style={{
              backgroundColor: colors.lighestGray,
              padding: 15,
              borderRadius: 6,
              marginTop: 30,
            }}
          >
            <NtText style={styles.title}>
              Please fill the require fields *
            </NtText>

            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              {this.renderInput(
                "Phone Number",
                "Phone Number",
                this.state.phone,
                (text) => {
                  this.setState({ phone: text });
                }
              )}
              {this.renderInput(
                "Extension *",
                "Extension",
                this.state.extension,
                (text) => {
                  this.setState({ extension: text });
                },
                {
                  marginLeft: 15,
                },
                { fontWeight: "700" }
              )}
              {this.renderInput(
                "Passwowrd *",
                "Password",
                this.state.password,
                (text) => {
                  this.setState({ password: text });
                },
                {
                  marginLeft: 15,
                },
                { fontWeight: "700" }
              )}
            </View>

            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              {this.renderInput(
                "Name *",
                "Name",
                this.state.name,
                (text) => {
                  this.setState({ name: text });
                },
                {},
                { fontWeight: "700" }
              )}
              {this.renderInput(
                "Description *",
                "Description",
                this.state.description,
                (text) => {
                  this.setState({ description: text });
                },
                { marginLeft: 15 },
                { fontWeight: "700" }
              )}
            </View>

            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: 20,
                alignItems: "flex-end",
              }}
            >
              {this.renderInput("Title", "Title", this.state.title, (text) => {
                this.setState({ title: text });
              })}
              {this.renderInput(
                "Location",
                "Location",
                this.state.location,
                (text) => {
                  this.setState({ location: text });
                },
                {
                  marginLeft: 15,
                }
              )}
            </View>
          </View>

          <NtFormButtonNext
            loading={this.state.loading}
            onPress={() => {
              this.handleExtensionCreate();
            }}
            title="Create Extension"
            containerStyle={{ width: 180, height: 45, marginTop: 20 }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.darkest,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darker,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: "white",
    borderRadius: 8,
  },
});

export default CreateExtensionController;
