import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";
import NtText from "../../components/NtText";
import NotFoundController from "../../common/controllers/NoFoundController";

function NtCrewAppFeatureSection(props) {
  const isMobile = useMobile();

  const renderOption = (title, subtitle, containerStyle) => {
    return (
      <View>
        <View
          style={[
            { flexDirection: "row", alignItems: "center" },
            containerStyle,
          ]}
        >
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: colors.lightBlue,
            }}
          />
          <NtText style={[styles.subtitle, { marginLeft: 15 }]}>{title}</NtText>
        </View>
        <NtText style={[styles.text, { marginTop: 8 }]}>{subtitle}</NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.darkBlue}>
      <NtText style={styles.title}>FEATURES</NtText>
      <View
        style={{
          marginTop: 30,
          width: "100%",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <View style={{ flex: 1, marginTop: isMobile ? 30 : 0 }}>
          {renderOption(
            "Crew-Cast",
            "Crew members can receive broadcasts and notifications from the cruise ship.",
            { marginTop: 35 }
          )}

          {renderOption(
            "Crew-Fone",
            "A feature-rich Android smartphone created specifically for the crew of a ship.",
            { marginTop: 20 }
          )}

          {renderOption(
            "Crew-Control",
            "Management can set restrictions to limit communications during certain times.",
            { marginTop: 20 }
          )}

          {renderOption(
            "Crew-Home",
            "Crew members can communicate with friends/family at home per company policies.",
            { marginTop: 20 }
          )}
        </View>
        <Image
          style={[
            styles.image,
            isMobile ? { height: 250, marginTop: 30 } : { height: 460 },
          ]}
          source={require("../../assets/images/marketing-guestapp-video.png")}
        />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 300,
    height: 400,
    resizeMode: "contain",
  },
  title: {
    fontSize: 35,
    fontWeight: "800",
    color: colors.lightBlue,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 22,
    fontWeight: "700",
    color: "white",
    textAlign: "center",
  },
  text: {
    fontSize: 18,
    fontWeight: "500",
    color: "white",
  },
});

export default NtCrewAppFeatureSection;
