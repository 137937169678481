import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../../config/colors";
import NtText from "../../../components/NtText";
import { useMobile } from "../../../hooks/useMobile";

function NtProducSection(props) {
  const isMobile = useMobile();

  const renderMiniSection = (title, description) => {
    return (
      <View style={{ maxWidth: 150 }}>
        <NtText style={{ fontSize: 20, fontWeight: "700", color: "#2d9cff" }}>
          {title}
        </NtText>
        <NtText
          style={{
            fontSize: 12,
            fontWeight: "500",
            color: colors.lightest,
            marginTop: 10,
            lineHeight: 16,
          }}
        >
          {description}
        </NtText>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <NtText style={[styles.title, { textAlign: "center", marginTop: 30 }]}>
        Integrated Hotel & Guest Communication Experience
      </NtText>
      <NtText
        style={[
          {
            fontSize: 22,
            fontWeight: "800",
            color: "white",
            textAlign: "center",
            marginTop: 40,
          },
        ]}
      >
        PRODUCTS
      </NtText>

      <View
        style={{
          marginTop: 25,
        }}
      >
        {/* used for the background.... */}
        <View
          style={{
            position: "absolute",
            backgroundColor: colors.darkBlue,

            left: 0,
            width: "100%",
            height: "50%",
          }}
        />
        <View
          style={{
            position: "absolute",
            backgroundColor: "white",

            left: 0,
            top: "50%",

            width: "100%",
            height: "50%",
          }}
        />

        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={[styles.image]}
            source={require("../../../assets/images/marketing-hospitality-products-phones.png")}
          />
          <View
            style={{
              maxWidth: 300,
              marginTop: isMobile ? 0 : 40,
            }}
          >
            <NtText
              style={[
                {
                  fontSize: 26,
                  fontWeight: "800",
                  color: isMobile ? colors.darkBlue : "white",
                },
                isMobile
                  ? { color: colors.darkBlue, textAlign: "center" }
                  : { color: "white" },
              ]}
            >
              MOBILE <NtText style={{ color: "#2d9cff" }}>APP</NtText>
            </NtText>
            <NtText
              style={[
                {
                  color: colors.almostBlack,
                  fontSize: 14,
                  fontWeight: "500",
                },
                isMobile
                  ? { marginTop: 10, marginBottom: 20 }
                  : { marginTop: 80 },
              ]}
            >
              Stay connected with hotel staff, friends and family staying on the
              same property using the app's unlimited calls and messages for the
              entire stay.
            </NtText>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-evenly",
          paddingTop: 35,
          paddingBottom: 35,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-around",
          }}
        >
          {renderMiniSection(
            "Organized",
            "Schedule bookings, such as spa visits or conference rooms."
          )}
          {renderMiniSection(
            "Fast",
            "Call the hotel directory to order food and or drinks from any place with WiFi connectivity on the property."
          )}
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            flex: 1,
          }}
        >
          {renderMiniSection(
            "Easy",
            "Stay connected with hotel staff, friends and family staying on the same property using the app's unlimited calls and messages for the entire stay."
          )}

          {renderMiniSection(
            "Efficient",
            "Organize group meetups stay in contact and share their favorite photos/videos with everyone in their party with group conference calling and group messaging."
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
  },
  title: {
    fontSize: 20,
    fontWeight: "500",
    color: "white",
  },
  image: {
    width: 380,
    height: 380,
    resizeMode: "contain",
  },
});

export default NtProducSection;
