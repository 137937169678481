import React from "react";
import { View, StyleSheet, Image, FlatList } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMaritimeTM from "./NtMaritimeTM";
import { useMobile } from "../../hooks/useMobile";

function NtMaritimeSection({}) {
  const isMobile = useMobile();

  const data = [
    {
      image: require("../../assets/images/marketing-maritime-app-to-app.png"),
      title: "App to App\nCalls",
    },
    {
      image: require("../../assets/images/marketing-maritime-ship-to-app.png"),
      title: "App to Ship PBX\nCalls",
    },
    {
      image: require("../../assets/images/marketing-maritime-app-to-ship.png"),
      title: "Ship PBX to Appn\nCalls",
    },
    {
      image: require("../../assets/images/marketing-maritime-app-to-app.png"),
      title: "App to Off-Ship\nCalls",
    },
  ];

  //ship-wheel
  const renderRow = (item, index) => {
    return (
      <View
        style={[
          { alignItems: "center", width: 150, marginLeft: 30, marginRight: 30 },
        ]}
      >
        <Image style={styles.icon} source={item.image} />
        <NtText
          style={[
            {
              textAlign: "center",
              color: "white",
              fontSize: 14,
              fontWeight: "600",
              marginTop: 6,
            },
          ]}
        >
          {item.title}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          maxWidth: 820,
          marginBottom: 100,
        }}
      >
        <NtMaritimeTM containerStyle={{ marginTop: 20 }} />
        <NtText style={[styles.header, { marginTop: 30 }]}>
          PASSENGER & CREW COMMUNICATION SOLUTIONS FOR CRUISE LINES
        </NtText>
        <NtText style={[styles.text, { marginTop: 10 }]}>
          <NtText style={[styles.text, { fontWeight: "bold" }]}>
            We are an IT Solution Provider.
          </NtText>{" "}
          We develop and operate mission-critical communications networks,
          location services (including contact tracing and access controls), and
          Telehealth technologies for the maritime industry worldwide.
        </NtText>
        <View
          style={{
            marginTop: 40,
          }}
        >
          <Image
            style={styles.image}
            source={require("../../assets/images/marketing-ship-comunication.png")}
          />
        </View>
        <NtText style={[styles.header, { marginTop: 40 }]}>
          ONBOARD COMMUNICATIONS & WORLD CALLING + MESSAGING FOR PASSENGERS
        </NtText>
        <NtText style={[styles.text, { marginTop: 10 }]}>
          Passengers can stay connected with friends and family on onboard with
          the app's unlimited onboard calls and messages for the entire cruise.
          Groups onboard can also organize meetups, stay in contact and share
          their favorite photos with everyone in their party with group
          messaging through the app. NT Maritime's robust technology allows
          guests to enjoy a cruise vacation with the ability to enjoy
          communication and connectivity among friends and family on their
          cruise just as they would if they were on a land-based vacation.
        </NtText>

        <FlatList
          key={isMobile ? 2 : 4}
          style={styles.flatlist}
          data={data}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 25 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderRow(item, index)}
          numColumns={isMobile ? 2 : 4}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
    alignItems: "center",
    padding: 20,
    width: "100%",
  },
  icon: {
    height: 35,
    width: 80,
    resizeMode: "contain",
  },
  header: {
    color: colors.lightBlue,
    fontSize: 23,
    textAlign: "center",
  },
  text: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  sectionText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  image: {
    height: 350,
    width: 350,
  },
  flatlist: {
    marginTop: 40,
  },
});

export default NtMaritimeSection;
