import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import MessagingClient from "../utils/MessagingClient";

function NtConnectionStatus({ containerStyle }) {
  const client = new MessagingClient();
  const [status, setStatus] = useState(client.isConnected);

  client.addConnectionCallback((connection) => {
    setStatus(connection.connected);
  });

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: status ? "green" : "red" },
        containerStyle,
      ]}
    ></View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
});

export default NtConnectionStatus;
