import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtDevider from "../../components/NtDevider";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import useSelection from "../../hooks/useSelection";
import moment from "moment";
import { handleCSVDownloadData } from "../utils/GatewayParser";

function NtGatewayCSVButtonFieldsPicker({ onCancel, data, maxGateway }) {
  const parameters = [
    {
      title: "Include temporary extension",
    },
  ];

  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const selection = useSelection();

  const handleFileDownload = async () => {
    const response = await handleCSVDownloadData(
      data,
      maxGateway,
      selection.isSelected(parameters[0].title)
    );
    const fileData = response.data;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `Gateway-Report-${
      selection.isSelected(parameters[0].title) ? "all" : "room"
    }-${moment().format("YYYYMMDD")}.csv`;
    link.href = url;
    link.click();
  };

  const renderCancel = () => {
    return (
      <NtTouchableEffect style={styles.cancelButton} onPress={onCancel}>
        <NtText
          style={{ fontSize: 13, fontWeight: "600", color: colors.darkest }}
        >
          Cancel
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderDownload = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          styles.addButton,
          containerStyle,
          { backgroundColor: colors.blue },
        ]}
        onPress={() => {
          handleFileDownload();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
          Download
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={item.title}
        onPress={() => {
          if (selection.isSelected(item.title)) {
            selection.removeSelection(item.title);
          } else {
            selection.addSelection(item.title);
          }
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtText
            style={[
              styles.rowText,
              {
                flex: 1,
                color: selection.isSelected(item.title)
                  ? colors.darkest
                  : colors.lighGray,
              },
            ]}
          >
            {item.title}
          </NtText>
          {selection.isSelected(item.title) && (
            <NtMaterialIcon name="check" size={20} color={colors.darkest} />
          )}
        </View>

        {index != parameters.length - 1 && <NtDevider />}
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Download CSV</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select specific parameters for this report
      </NtText>

      <View style={{ marginTop: 20, marginBottom: 20 }}>
        {parameters.map((each, index) => renderRow(each, index))}
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        {renderCancel()}
        {renderDownload()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtGatewayCSVButtonFieldsPicker;
