import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";
import NtActivityIndicator from "./NtActivityIndicator";
import NtMaterialIcon from "./NtMaterialIcon";
import colors from "../config/colors";

function NtFormButtonNext({
  containerStyle,
  title = "Next",
  loading = false,
  onPress,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtText style={{ color: "white", fontSize: 14, fontWeight: "600" }}>
        {title}
      </NtText>
      {loading && (
        <NtActivityIndicator size="small" containerStyle={{ marginLeft: 6 }} />
      )}
      {!loading && (
        <NtMaterialIcon
          style={{ marginLeft: 6 }}
          name="arrow-right"
          color="white"
          size={25}
        />
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.blue,
    justifyContent: "center",
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

export default NtFormButtonNext;
