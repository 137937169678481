import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import NtPartnerItem from "./NtPartnerItem";
import { useMobile } from "../../hooks/useMobile";
import colors from "../../config/colors";

function NtPartnerHAItem({ items }) {
  const isMobile = useMobile();

  const renderRow = (item, index) => {
    return <NtPartnerItem item={item} />;
  };

  const renderLine = (style) => {
    return (
      <View
        style={[
          {
            width: "100%",
            height: 1,
            backgroundColor: colors.lighGray,
          },
          style,
        ]}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          borderWidth: 1,
          borderColor: colors.lighGray,
          borderRadius: 8,
          padding: 1,
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 0,
            borderColor: colors.lighGray,
            paddingTop: 20,
            paddingBottom: 30,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <NtText style={[styles.title, { marginTop: 10 }]}>
            HOLLAND AMERICA GROUP
          </NtText>
          <NtText style={[styles.subtitle, { marginTop: 6 }]}>SERVING</NtText>

          <View style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}>
            {renderLine({ marginTop: 20, marginBottom: 20 })}
            {/* {renderLine({ marginTop: 1, marginBottom: 20 })} */}
          </View>

          <FlatList
            key={isMobile ? 2 : 1}
            style={{}}
            data={items}
            ItemSeparatorComponent={() => {
              return <View style={{ height: 25 }} />;
            }}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => renderRow(item, index)}
            numColumns={isMobile ? 2 : 1}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { padding: 20 },
  title: {
    fontSize: 16,
    color: colors.darkBlue,
  },
  subtitle: {
    fontSize: 16,
    color: colors.lighGray,
  },
});

export default NtPartnerHAItem;
