import React, { useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import UserContext from "../../context/UserContext";
import Logger from "../../common/utils/Logger";
import NtText from "../../components/NtText";
import NtDisclosure from "../../components/NtDisclosure";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtEnvironmentPicker from "./NtEnvironmentPicker";

function NtEnvironmentFieldController({ containerStyle }) {
  const context = useContext(UserContext);
  const environmentPickerRef = useRef(null);

  const renderLocationPickerModal = () => {
    return (
      <NtModal
        ref={environmentPickerRef}
        renderBody={
          <NtEnvironmentPicker
            onPress={(location) => {
              environmentPickerRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        environmentPickerRef?.current?.show();
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!context.user?.environment && (
          <NtText style={[styles.text, { flex: 1 }]}>Select Environment</NtText>
        )}
        {context.user?.environment && (
          <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
            <NtText style={styles.text}>
              {context?.user?.environment?.name}
            </NtText>
          </View>
        )}
        <NtDisclosure name="chevron-down" color={colors.lighGray} />
      </View>
      {renderLocationPickerModal()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    backgroundColor: colors.lighestGray,
    borderRadius: 20,
    padding: 10,
  },
  text: {
    color: colors.lighGray,
    fontSize: 15,
    fontWeight: "500",
    marginLeft: 6,
  },
});

export default NtEnvironmentFieldController;
