import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";

class BaseExtensionController extends BaseController {
  state = {};
}

const styles = StyleSheet.create({
  container: {},
});

export default BaseExtensionController;
