import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";

function NtModalUnactiveButton({ title, containerStyle, onPress }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtText
        style={{ fontSize: 13, fontWeight: "600", color: colors.darkest }}
      >
        {title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtModalUnactiveButton;
