import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";

function NtDevider({ containerStyle }) {
  return <View style={[styles.container, containerStyle]} />;
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 1,
    backgroundColor: colors.lighestGray,
  },
});

export default NtDevider;
