import React from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import NtButton from "../../components/NtButton";
import colors from "../../config/colors";
import NtTextInput from "../../components/NtTextInput";
import {
  authenticate,
  authenticateRequestCode,
  authenticateUser,
} from "../../api/auth";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import BaseController from "../../common/base/BaseController";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtLoginButton from "../components/NtLoginButton";
import NtEnvironmentFieldController from "../../root/components/NtEnvironmentFieldController";
import settings from "../../config/settings";
import routes from "../../navigation/routes";
import BaseAuthController from "./BaseAuthController";

class AuthController extends BaseAuthController {
  loginTypes = [
    {
      id: "pin",
      icon: "dialpad",
      title: "Pin",
    },
    {
      id: "password",
      icon: "form-textbox-password",
      title: "Password",
    },
  ];

  state = {
    username: "",
    password: "",
    loading: false,

    type: this.loginTypes[0],
  };

  componentDidMount() {
    document.title = "MARITIME | Log In";
  }

  isFieldsRequired = () => {
    if (this.state.type?.id == "pin") {
      return this.state.username && this.context.user?.environment;
    } else if (this.state.type?.id == "password") {
      return (
        this.state.username &&
        this.state.password &&
        this.context.user?.environment
      );
    }
    return false;
  };

  handleLogin = async () => {
    if (!this.isFieldsRequired()) {
      showToast("Oops...", "All fields required.", "error");
      return;
    }

    let extension = this.state.username;
    if (extension?.includes(settings.accountPrefixPhy)) {
      showToast(
        "Oops...",
        `Please remove the (${settings.accountPrefixPhy}) extension prefix`
      );
      return;
    }

    this.setState({ loading: true });

    if (!extension?.includes(settings.accountPrefixApp)) {
      extension = settings.accountPrefixApp + extension;
    }

    if (this.state.type?.id == "pin") {
      const response = await authenticateRequestCode(
        `${extension}@${this.context.user?.environment?.domain}`
      );
      if (response.ok) {
        const params = {
          extension: extension,
        };
        this.props.navigation.navigate(routes.authcode, params);
      } else {
        if (response.status == 404) {
          showToast(
            "Oops...",
            "Login using Pin is not allowed for this extension. Please use passsword instead"
          );
        } else if (response.status == 409) {
          showToast("Oops...", "Unable to find extension.");
        } else {
          showToast(
            "Oops...",
            "Unable to process request. Please try again later."
          );
        }
      }
    } else if (this.state.type?.id == "password") {
      const response = await authenticate(
        `${extension}@${this.context.user?.environment?.domain}`,
        this.state.password
      );

      if (response.ok) {
        this.setState({ username: "", password: "" });

        const params = this.parseParams(response.data);
        this.context.updateUser({
          ...this.context.user,
          ...params,
          authenticated: true,
        });
      } else {
        showToast("Oops...", "Invalid username/password convination", "error");
      }
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer
        showFooter={true}
        renderTitleView={<View />}
        containerStyle={{ backgroundColor: colors.lighestGray }}
      >
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 550,
              minWidth: 400,
              backgroundColor: "white",
              padding: 35,
              borderRadius: 10,
            }}
          >
            <NtText style={[styles.title]}>Login</NtText>
            <NtText style={[styles.subtitle, { marginTop: 6 }]}>
              Please sign in to continue
            </NtText>

            <NtTextInput
              textStyle={{ flex: 1 }}
              containerStyle={[styles.input, { marginTop: 30 }]}
              placeholder={"Extension"}
              placeholderTextColor={colors.lighGray}
              value={this.state.username}
              autoCorrect={false}
              autoCapitalize="none"
              onChangeText={(text) => this.setState({ username: text })}
            />

            {this.state.type?.id == "password" && (
              <NtTextInput
                textStyle={{ flex: 1 }}
                containerStyle={[styles.input, { marginTop: 15 }]}
                placeholder={"Password"}
                placeholderTextColor={colors.lighGray}
                showEye={true}
                value={this.state.password}
                secureTextEntry={true}
                autoCorrect={false}
                autoCapitalize="none"
                onChangeText={(text) => this.setState({ password: text })}
              />
            )}

            <NtEnvironmentFieldController containerStyle={{ marginTop: 15 }} />

            <View style={{ marginTop: 35, alignItems: "flex-end" }}>
              <NtLoginButton
                onPress={
                  this.isFieldsRequired()
                    ? () => {
                        this.handleLogin();
                      }
                    : null
                }
                onOptionPress={(selection) => {
                  this.setState({ type: selection });
                }}
                loading={this.state.loading}
                options={this.loginTypes}
                selected={this.state.type}
              />
            </View>
          </View>
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },

  input: {},
  title: {
    fontSize: 44,
    fontWeight: "bold",
    color: colors.darkest,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    color: colors.blue,
  },
  info: {
    textAlign: "center",
    color: colors.darker,
  },
});

export default AuthController;
