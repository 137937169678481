import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtHeader from "../components/NtHeader";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtPBXFeatureSection from "../components/NtPBXFeatureSection";
import NtPBXTextSection from "../components/NtPBXTextSection";
import NtPBXMoreFeatureSection from "../components/NtPBXMoreFeatureSection";
import NtPBXHeaderSection from "../components/NtPBXHeaderSection";

class MarketingPBXSystemController extends BaseController {
  state = {};
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHeader
            containerStyle={{ position: "relative" }}
            hoverEnabled={false}
          />
          <NtPBXHeaderSection />
          <NtPBXFeatureSection />
          <NtPBXTextSection />
          <NtPBXMoreFeatureSection />
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingPBXSystemController;
