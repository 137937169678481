import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtNavigationButton({ isMobile, selected, title, icon, onPress }) {
  return (
    <NtTouchableEffect onPress={onPress} style={styles.container}>
      {!isMobile && (
        <NtText
          style={[
            styles.title,
            selected ? { fontWeight: "800" } : {},
            { marginRight: 6 },
          ]}
        >
          {title}
        </NtText>
      )}
      <NtMaterialIcon
        name={icon}
        size={20}
        color={selected ? "white" : colors.lighter}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: colors.navigationTintColor,
    fontWeight: "600",
    fontSize: 13,
  },
});

export default NtNavigationButton;
