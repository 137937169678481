import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtHeader from "../components/NtHeader";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtGuestAppSection from "../components/NtGuestAppSection";
import NtGuestAppVoiceSection from "../components/NtGuestAppVoiceSection";
import NtGuestAppVideoSection from "../components/NtGuestAppVideoSection";
import NtguestAppChatSection from "../components/NtguestAppChatSection";
import NtGuestAppFeatureSection from "../components/NtGuestAppFeatureSection";

class MarketingGuestAppController extends BaseController {
  state = {};
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHeader
            containerStyle={{ position: "relative" }}
            hoverEnabled={false}
          />
          <NtGuestAppSection />
          <NtGuestAppVoiceSection />
          <NtGuestAppVideoSection />
          <NtguestAppChatSection />
          <NtGuestAppFeatureSection />
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MarketingGuestAppController;
