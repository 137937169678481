import React from "react";
import { View, StyleSheet, Image, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtButton from "../../components/NtButton";
import MyPDF from "../../assets/files/NCL+netTALKMaritimeCaseStudy.pdf";
import { useMobile } from "../../hooks/useMobile";

function NtCommunicationSection(props) {
  const isMobile = useMobile();
  const data = [
    {
      image: require("../../assets/images/marketing-comunication-calling.png"),
      title: "Core Feature:\nCalling",
    },
    {
      image: require("../../assets/images/marketing-comunication-video.png"),
      title: "Core Feature:\nVideo Calling",
    },
    {
      image: require("../../assets/images/marketing-comunication-messaging.png"),
      title: "Core Feature:\nMessaging",
    },
    {
      image: require("../../assets/images/marketing-comunication-voicemail.png"),
      title: "Core Feature:\nVoicemail",
    },
  ];
  const renderRow = (item, index) => {
    return (
      <View style={{ alignItems: "center", marginLeft: 20, marginRight: 20 }}>
        <Image style={styles.icon} source={item.image} />
        <NtText
          style={[
            {
              textAlign: "center",
              color: "white",
              fontSize: 14,
              fontWeight: "600",
              marginTop: 6,
            },
          ]}
        >
          {item.title}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          maxWidth: 820,
        }}
      >
        <NtText style={[styles.header]}>ONBOARD COMMUNICATIONS FEATURES</NtText>

        <FlatList
          key={isMobile ? 2 : 4}
          style={styles.flatlist}
          data={data}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 25 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderRow(item, index)}
          numColumns={isMobile ? 2 : 4}
        />

        <a
          style={{ textDecoration: "none", marginTop: 30 }}
          href={MyPDF}
          download="NCL+netTALKMaritimeCaseStudy"
          target="_blank"
        >
          <NtButton
            containerStyle={{ width: isMobile ? 260 : 480 }}
            text={
              isMobile
                ? "DOWNLOAD CASE STURY (PDF)"
                : "DOWNLOAD THE NORWEGIAN CRUISE LINE CASE STUDY (PDF)"
            }
          />
        </a>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
    paddingBottom: 50,
  },
  icon: {
    height: 85,
    width: 85,
    resizeMode: "contain",
  },
  flatlist: {
    marginTop: 30,
    marginBottom: 30,
  },
  header: {
    color: "white",
    fontSize: 23,
    textAlign: "center",
  },
});

export default NtCommunicationSection;
