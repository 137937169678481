import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Animated,
  Image,
  SafeAreaView,
  Text,
  Platform,
} from "react-native";

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import Logger from "../../common/utils/Logger";
import NtButton from "../../components/NtButton";

const { Value, Text: AnimatedText } = Animated;
const CELL_COUNT = 6;

const animationsColor = [...new Array(CELL_COUNT)].map(() => new Value(0));
const animationsScale = [...new Array(CELL_COUNT)].map(() => new Value(1));
const animateCell = ({ hasValue, index, isFocused }) => {
  Animated.parallel([
    Animated.timing(animationsColor[index], {
      useNativeDriver: false,
      toValue: isFocused ? 1 : 0,
      duration: 250,
    }),
    Animated.spring(animationsScale[index], {
      useNativeDriver: false,
      toValue: hasValue ? 0 : 1,
      duration: hasValue ? 300 : 250,
    }),
  ]).start();
};

let currentValue = "";

function NtInputCode({
  containerStyle,
  onTextChange,
  cellBackgroundColor = "#fff",
  cellNotEmptyColor = "#3557b7",
  cellActiveBackgroundColor = "#f7fafe",
}) {
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const DEFAULT_CELL_BG_COLOR = cellBackgroundColor;
  const NOT_EMPTY_CELL_BG_COLOR = cellNotEmptyColor;
  const ACTIVE_CELL_BG_COLOR = cellActiveBackgroundColor;

  const renderCell = ({ index, symbol, isFocused }) => {
    const hasValue = Boolean(symbol);
    const animatedCellStyle = {
      backgroundColor: hasValue
        ? animationsScale[index].interpolate({
            inputRange: [0, 1],
            outputRange: [NOT_EMPTY_CELL_BG_COLOR, ACTIVE_CELL_BG_COLOR],
          })
        : animationsColor[index].interpolate({
            inputRange: [0, 1],
            outputRange: [DEFAULT_CELL_BG_COLOR, ACTIVE_CELL_BG_COLOR],
          }),
      borderRadius: animationsScale[index].interpolate({
        inputRange: [0, 1],
        outputRange: [styles.cell.height, styles.cell.borderRadius],
      }),
      transform: [
        {
          scale: animationsScale[index].interpolate({
            inputRange: [0, 1],
            outputRange: [0.2, 1],
          }),
        },
      ],
    };

    // Run animation on next event loop tik
    // Because we need first return new style prop and then animate this value
    setTimeout(() => {
      animateCell({ hasValue, index, isFocused });
    }, 0);

    return (
      <AnimatedText
        key={index}
        style={[styles.cell, animatedCellStyle]}
        onLayout={getCellOnLayoutHandler(index)}
      >
        {symbol || (isFocused ? <Cursor /> : null)}
      </AnimatedText>
    );
  };

  if (onTextChange && currentValue != value) {
    currentValue = value;
    setTimeout(() => {
      onTextChange(value);
    }, 200);
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <CodeField
        ref={ref}
        {...props}
        value={value}
        onChangeText={setValue}
        cellCount={CELL_COUNT}
        rootStyle={styles.codeFieldRoot}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={renderCell}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},

  codeFieldRoot: {
    justifyContent: "center",
  },
  cell: {
    marginHorizontal: 12,
    flex: 1,
    height: 50,

    fontSize: 30,
    textAlign: "center",
    borderRadius: 8,
    color: "#3759b8",
    backgroundColor: "#fff",

    // IOS
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    // Android
    elevation: 2,
  },
});

export default NtInputCode;
