import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import NtActivityIndicator from "./NtActivityIndicator";
import NtText from "./NtText";
import colors from "../config/colors";

function NtFileButton({
  containerStyle,
  onPress,
  loading = false,
  title,
  icon,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      {!loading && <NtMaterialIcon name={icon} color={colors.lighGray} />}
      {loading && <NtActivityIndicator size="small" color={colors.lighGray} />}
      <NtText style={[styles.title, { marginLeft: 6 }]}>{title}</NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 6,
    borderColor: colors.lighGray,
    borderWidth: 1,
  },
  title: {
    color: colors.lighGray,
  },
});

export default NtFileButton;
