import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";

function NtRootPageFooter({}) {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../assets/images/nt-maritime-logo-blue.png")}
      />
      <NtText style={[styles.copyright, { marginTop: 8 }]}>
        Copyright © 2017 - {new Date().getFullYear()} NT MARINE APPS LLC All
        Rights Reserved.
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    shadowColor: "black",
    shadowOffset: { width: 0, height: -1 },
    shadowRadius: 2,
    shadowOpacity: 0.26,
    elevation: 1,
    marginTop: 2,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 30,
    resizeMode: "contain",
  },
  copyright: {
    color: colors.blue,
    textAlign: "center",
    fontSize: 11,
  },
});

export default NtRootPageFooter;
