import React, { act } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import BaseController from "../../common/base/BaseController";
import NtCircularProgress from "../components/NtCircularProgress";
import { fetchStatistics } from "../../api/dashboard";
import NtSemiCircularProgress from "../components/NtSemiCircularProgress";
import Logger from "../../common/utils/Logger";

class DashboardController extends BaseController {
  state = {
    statistics: null,
    loading: false,
  };

  componentDidMount() {
    this.handleStatisticsDataFetch();
  }

  handleStatisticsDataFetch = async () => {
    this.setState({ loading: true });
    const response = await fetchStatistics(
      this.context.user?.environment?.domain
    );

    Logger("---------- response", response);
    if (response.ok) {
      this.setState({ statistics: response.data });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer pageTitle={"Dashboard"} showFooter={true}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 35,
          }}
        >
          <NtCircularProgress
            title="Total Extensions"
            label={this.state.statistics?.extensions}
            loading={this.state.loading}
          />
          <NtCircularProgress
            containerStyle={{ marginLeft: 20 }}
            title="Total Ring Groups"
            label={this.state.statistics?.ringGroups}
            loading={this.state.loading}
          />
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <NtCircularProgress
            title="Messages"
            loading={this.state.loading}
            label={this.state.statistics?.messages}
          />

          <NtCircularProgress
            containerStyle={{ marginLeft: 20 }}
            title="Voicemails"
            loading={this.state.loading}
            label={this.state.statistics?.voicemails}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 20,
    fontWeight: "800",
    color: colors.darkest,
  },
});

export default DashboardController;
