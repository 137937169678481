import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";
import Logger from "../common/utils/Logger";
import MessageItem from "../maritime/utils/Message/MessageItem";

const messageFromPayload = (payload, me, receiver) => {
  let message = new MessageItem(null, null, null, JSON.parse(payload));

  if (message.mSender == me && !message.mAddress?.includes(":")) {
    //will handle outgoing....
    message.mType = 1;
    message.mAddress = receiver;
  } else if (message.mAddress?.includes(":")) {
    //will handle the group....
    if (message.mSender == me) {
      message.mType = 1;
    }
  }
  return message;
};

export const fetchConversations = async (user) => {
  const me = generateFullUser(user);
  let response = await client.get(
    `/chat/message?user=${me}&pagesize=50&newestOnly=true`
  );

  if (response.ok && response.data?.history?.length > 0) {
    response.data = response.data?.history?.map((each) => {
      return messageFromPayload(each.payload, me, each?.receiver);
    });
  } else {
    response.data = [];
  }

  //will need to sort here
  response.data?.sort((a, b) => {
    return b.mDate - a.mDate;
  });

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchHistory = async (user, to) => {
  let response = await fetchConversations(user);

  if (response.ok) {
    //will select which one is the one that belongs to the convesation...
    const message = response.data?.find((each) => each.mAddress == to);

    if (message) {
      response = await client.get(
        `/chat/message?user=${user},${to}&pagesize=50&skipFromMessageId=${message?.mMessageId}&orderby=creationdate&order=ASC`
      );

      if (response.ok) {
        response.data = response?.data?.history?.map((each) => {
          return messageFromPayload(each.payload, user, each?.receiver);
        });
        response.data = [...response?.data, message];
      }
    } else {
      response.data = [];
    }
  } else {
    response.data = [];
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
