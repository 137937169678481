import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtExtensionReportTabItem({
  containerStyle,
  item,
  isSelected = false,
  onPress,
}) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        {
          backgroundColor: isSelected ? "#1d1d1d" : "white",
        },
        containerStyle,
      ]}
      onPress={onPress}
    >
      <View>
        <NtText
          style={[
            styles.title,
            {
              color: isSelected ? "white" : "#1d1d1d",
            },
          ]}
        >
          {item.title}
        </NtText>
      </View>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 30,
    borderWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderColor: "#1d1d1d",
  },
  title: {
    fontSize: 15,
    fontWeight: "600",
  },
});

export default NtExtensionReportTabItem;
