const featurecodedata = [
  //basic
  {
    code: "*1",
    name: "Call Transfer",
    details: "Transfer a call to another extension",
  },
  {
    code: "*2",
    name: "Record Active Call",
    details: "",
  },
  {
    code: "*4",
    name: "Attended Call Transfer",
    details:
      "Attended call transfer to another extension. After extension number press #",
  },
  {
    code: "*411",
    name: "Directory",
    details: "DIR to dial by name.",
  },
  {
    code: "*3472",
    name: "DISA",
    details:
      "DISA followed by Administrative PIN to receive a dialtone and call out",
  },
  {
    code: "*67<phone number>",
    name: "Call Privacy",
    details: "Activate call privacy",
  },
  {
    code: "*69",
    name: "Call Return",
    details: "Call back the last incoming number",
  },
  {
    code: "*732",
    name: "Record",
    details: "*REC followed by Administrative PIN to record a message",
  },
  {
    code: "*8[ext]",
    name: "Extension Intercom",
    details: "Page a specific extension.",
  },
  {
    code: "*870",
    name: "Redial",
    details: "Redial a number",
  },
  {
    code: "*9171",
    name: "Talking Date",
    details: "Current server date",
  },
  {
    code: "*9170",
    name: "Talking Time",
    details: "Current server time",
  },
  {
    code: "*9172",
    name: "Talking Date & Time",
    details: "Current server date & time",
  },
  {
    code: "*925",
    name: "Wakeup Call",
    details: "Schedule a wakeup call",
  },
  {
    code: "*78",
    name: "Enable DND",
    details: "Enable Do Not Disturb",
  },
  {
    code: "*79",
    name: "Disable DND",
    details: "Disable Do Not Disturb",
  },
  {
    code: "*9888",
    name: "FreeSWITCH Conference",
    details: "Connects to Cluecon Weekly",
  },
  {
    code: "*0[ext]",
    name: "Speed Dial",
    details: "Speed dial an extension",
  },
  {
    code: "*21",
    name: "Follow Me",
    details: "Set the Follow Me number",
  },
  {
    code: "*72",
    name: "Enable Call Forward",
    details: "Enables Call Forward",
  },
  {
    code: "*73",
    name: "Disable Call Forward",
    details: "Disables Call Forward",
  },
  {
    code: "*74",
    name: "Call Forward",
    details: "Toggle Call Forward enable/disable",
  },

  //call parking
  {
    code: "*5900",
    name: "Valet Park",
    details:
      "Attended Transfer (park). The park extension will be played back to you",
  },
  {
    code: "*5901-5999",
    name: "Valet Un-Park",
    details: "Retrieve a Valet Parked call",
  },

  //advanced
  {
    code: "*8[ext]",
    name: "Extension Intercom",
    details: "Page a specific extension",
  },
  {
    code: "*33 <ext>",
    name: "Eavesdrop",
    details:
      "Listen to the call. Press 1 remote, 2 local, 3 full conversation, 0 mute",
  },
  {
    code: "** <ext>",
    name: "Intercept an extension",
    details: "Intercept a specific extension",
  },

  //voicemail
  {
    code: "*97",
    name: "Voicemail",
    details:
      "The system detects the extension, and will prompt for your password",
  },
  {
    code: "*98",
    name: "Check any Voicemail box",
    details:
      "The system will prompt for both your id (extension number) and password",
  },
  {
    code: "*4000",
    name: "Check any Voicemail box",
    details:
      "The system will prompt for both your id (extension number) and password",
  },
  {
    code: "*99<extension>",
    name: "Send to Voicemail",
    details: "Send a call directly to voicemail",
  },

  //MIscellaneous
  {
    code: "*9192",
    name: "Info",
    details: "Sends information to the console",
  },
  {
    code: "*9193",
    name: "Video Record",
    details: "Record Video",
  },
  {
    code: "*9194",
    name: "Video Playback",
    details: "Playback Video",
  },
  {
    code: "*9195",
    name: "Delay Echo",
    details: " Audio is played back after a slight dela",
  },
  {
    code: "*9196",
    name: "Echo Test",
    details: "Echo Test",
  },
  {
    code: "*9197",
    name: "Milliwatt Tone",
    details: "Tone Playback",
  },
  {
    code: "*9664",
    name: "Test MoH",
    details: "Test Music on Hold",
  },

  //NT
  {
    code: "*501",
    name: "Room Status",
    details: "System will read back the configuration for the current room",
  },
  {
    code: "*502",
    name: "Room Configure",
    details: "Will configure an extension to the room it is installed. ",
  },

  //Maid
  {
    code: "*503",
    name: "Maid Present",
    details: "Maid present",
  },
  {
    code: "*504",
    name: "Room clean",
    details: "Room cleaned",
  },
  {
    code: "*505",
    name: "Room not cleaned",
    details: "Room not cleaned",
  },
  {
    code: "*506",
    name: "Room out of service",
    details: "Room out of service",
  },
  {
    code: "*507",
    name: "Room to be instpected",
    details: "Room to be inspected",
  },
  {
    code: "*508",
    name: "Room occupied/clean",
    details: "Room occupied/clean",
  },
  {
    code: "*509",
    name: "Room occupied/not clean",
    details: "Room occupied/not clean",
  },
  {
    code: "*510",
    name: "Room vacant/clean",
    details: "Room vacant/clean",
  },
  {
    code: "*511",
    name: "Room vacant/not clean",
    details: "Room vacant/not clean",
  },

  //Schedule
  {
    code: "*513 / *613",
    name: "Schedule wakeup",
    details: "Schedule wake up",
  },

  {
    code: "*520 / *620",
    name: "Schedule event",
    details: "Schedule event",
  },

  {
    code: "*530",
    name: "Triger event",
    details: "Trigger Event, only admin extensions will have access to this.",
  },

  {
    code: "*540",
    name: "Reporting",
    details: "Will send a report depending on the selection",
  },
];

export default featurecodedata;
