import React, { useState, useContext } from "react";
import { View, StyleSheet, Image } from "react-native";
import Logger from "../../common/utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import { useNavigation } from "@react-navigation/native";
import routes from "../../navigation/routes";
import { useMobile } from "../../hooks/useMobile";
import NtHoverView from "../../components/NtHoverView";

function NtHeader({ containerStyle, hoverEnabled = true }) {
  const [isHovering, setIsHovering] = useState(hoverEnabled ? false : true);
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const context = useContext(UserContext);
  const navigation = useNavigation();

  const handleMouseOver = () => {
    if (hoverEnabled) {
      setIsHovering(true);
    }
  };

  const handleMouseOut = () => {
    if (hoverEnabled) {
      setIsHovering(false);
    }
  };

  const handleMyAccount = () => {
    if (context.user.authenticated) {
      navigation.navigate(routes.dashboard);
    } else {
      navigation.navigate(routes.auth);
    }
  };

  const actions = [
    {
      title: "My Account",
      icon: "account-circle-outline",

      action: () => {
        handleMyAccount();
      },
    },

    {
      title: "Visit Our Blog",
      icon: "open-in-new",
      action: () => {
        setOpen(false);
        window.open("https://blog.nettalkmaritime.com"); //window.open("http://199.193.189.220//blog");
      },
    },
  ];

  const tabs = [
    {
      title: "Guest App",
      textAlwaysShown: true,
      action: () => {
        setOpen(false);
        navigation.navigate(routes.guestapp);
      },
    },

    {
      title: "Crew App",
      textAlwaysShown: true,
      action: () => {
        setOpen(false);
        navigation.navigate(routes.crewapp);
      },
    },

    {
      title: "Hospitality PBX",
      textAlwaysShown: true,
      action: () => {
        setOpen(false);
        navigation.navigate(routes.hospitalitypbx);
      },
    },

    {
      title: "Telehealth",
      textAlwaysShown: true,
      action: () => {
        setOpen(false);
        navigation.navigate(routes.health);
      },
    },

    {
      title: "Starlink",
      textAlwaysShown: true,
      action: () => {
        setOpen(false);
        navigation.navigate(routes.starlink);
      },
    },
  ];

  const renderButton = (each, containerStyle) => {
    return (
      <NtTouchableEffect
        key={each.title}
        style={[
          { marginRight: 12, flexDirection: "row", alignItems: "center" },
          containerStyle,
        ]}
        onPress={each.action}
      >
        {(each.textAlwaysShown || !isMobile) && (
          <NtText
            style={{
              fontSize: 12,
              fontWeight: "600",
              color: isHovering ? colors.darkest : "white",
            }}
          >
            {each.title}
          </NtText>
        )}
        {each.icon && (
          <NtHoverView title={each.title} enabled={isMobile}>
            <NtMaterialIcon
              style={{ marginLeft: 6 }}
              name={each.icon}
              size={22}
              color={isHovering ? colors.darkest : "white"}
            />
          </NtHoverView>
        )}
      </NtTouchableEffect>
    );
  };

  const renderImage = () => {
    return (
      <NtTouchableEffect
        onPress={() => {
          navigation.navigate(routes.marketing);
        }}
      >
        <Image
          style={styles.logo}
          source={
            isHovering
              ? require("../../assets/images/nt-maritime-logo-blue.png")
              : require("../../assets/images/nt-maritime-logo-white.png")
          }
        />
      </NtTouchableEffect>
    );
  };

  const renderContent = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {renderImage()}

          {!isMobile && (
            <View style={{ flexDirection: "row" }}>
              {tabs.map((each) => renderButton(each))}
            </View>
          )}

          <View style={{ flexDirection: "row" }}>
            {renderButton(actions[0])}
            {!isMobile && renderButton(actions[1])}
            {isMobile &&
              renderButton({
                title: "Menu",
                icon: "menu",
                action: () => {
                  setOpen(!open);
                },
              })}
          </View>
        </View>
        {open && (
          <View style={{ marginLeft: 15 }}>
            {tabs.map((each) => renderButton(each, { marginTop: 15 }))}
            {renderButton(
              { ...actions[1], textAlwaysShown: true },
              { marginTop: 10 }
            )}
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <div
        style={{ flex: 1 }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            height: "100%",
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: isHovering ? "white" : "rgba(168, 168, 168, 0.2)",
          }}
        >
          {renderContent()}
        </View>
      </div>

      <View style={{ width: "100%", height: 1, backgroundColor: "white" }} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "transparnet",
    position: "absolute",
  },
  logo: {
    width: 130,
    height: 30,
    resizeMode: "contain",
  },
});

export default NtHeader;
