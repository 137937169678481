import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtButton from "../../components/NtButton";
import { useMobile } from "../../hooks/useMobile";

function NtAddonSection({ onLearnMore }) {
  const isMobile = useMobile();
  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          maxWidth: 820,
        }}
      >
        <Image
          style={[styles.image, isMobile ? { height: 200 } : { height: 400 }]}
          source={require("../../assets/images/marketing-addon.jpg")}
        />

        <NtText style={[styles.header, { marginTop: 30 }]}>
          OPTIONAL ADD-ON:
        </NtText>
        <NtText style={[styles.text, { marginTop: 10 }]}>
          SHIPBOARD PASSENGER & CREW HEALTH MONITORING
        </NtText>

        {onLearnMore && (
          <NtButton
            containerStyle={{ width: 110, marginTop: 30 }}
            text={"Learn More"}
            onPress={onLearnMore}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    alignItems: "center",
    padding: 20,
  },
  image: {
    width: "100%",
    height: 400,
    resizeMode: "contain",
  },
  header: {
    color: colors.lightBlue,
    fontSize: 23,
    fontWeight: "700",
    textAlign: "center",
  },
  text: {
    color: colors.darkest,
    fontSize: 16,
    textAlign: "center",
  },
});

export default NtAddonSection;
