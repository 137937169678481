import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";

function NtModalActiveButton({ enabled, onPress, title, containerStyle }) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        {
          backgroundColor: enabled ? colors.blue : colors.lighGray,
        },
        containerStyle,
      ]}
      onPress={onPress}
    >
      <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
        {title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
});

export default NtModalActiveButton;
