import React from "react";
import { View, StyleSheet, Image, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";

function NtHealthIncludedSection(props) {
  const isMobile = useMobile();
  const data = [
    {
      image: require("../../assets/images/marketing-health-included-arrows.png"),
      title: "Wayfinding Services",
    },
    {
      image: require("../../assets/images/marketing-health-included-message.png"),
      title: "Messaging Services",
    },
    {
      image: require("../../assets/images/marketing-health-included-map.png"),
      title: "Location Services",
    },
    {
      image: require("../../assets/images/marketing-health-included-graph.png"),
      title: "Heat Maps and Flow Services",
    },
  ];

  const renderRow = (item, index) => {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Image
          style={[{ resizeMode: "contain", width: 40, height: 40 }]}
          source={item.image}
        />
        <NtText
          style={{
            color: colors.lighGray,
            fontSize: 16,
            textAlign: "center",
            fontWeight: "700",
            marginTop: 15,
          }}
        >
          {item.title}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer>
      <NtText style={styles.title}>Other Included Services</NtText>

      <View style={{ marginTop: 40 }}>
        <FlatList
          key={isMobile ? 2 : 4}
          style={styles.flatlist}
          data={data}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 30 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderRow(item, index)}
          numColumns={isMobile ? 2 : 4}
        />
      </View>

      <NtText style={[styles.title, { marginTop: 50, color: colors.darkBlue }]}>
        NT MARITIME is a certified partner of Tritan Software
      </NtText>
      <View
        style={{ flexDirection: "row", marginTop: 40, alignItems: "center" }}
      >
        <NtText style={styles.text}>
          The world is also filled with compliance regulations from health
          authorities, such as the Center for Disease Control (CDC), WHO, CLIA,
          EU Healthy Gateways, Robert-Koch-Institute, and the cruise line Flag &
          Government Regulators. The Tritan Software SeaCare platform ensures
          these requirements are adhered to. The SeaCare platform can receive,
          process, monitor, protect, and manage the required health data of each
          person onboard (guests and crew), all of which are provided through
          the ongoing contactless health-check methods gathered and made
          available through NT MARITIME.
        </NtText>

        <Image
          style={[
            { resizeMode: "contain", width: 180, height: 180, marginLeft: 30 },
          ]}
          source={require("../../assets/images/marketing-health-included-tritan-partner.png")}
        />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lightBlue,
    fontSize: 23,
    fontWeight: "700",
  },
  text: {
    color: colors.lighGray,
    fontSize: 16,
    lineHeight: 24,
  },
});

export default NtHealthIncludedSection;
