import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseMaritimeController from "./BaseMaritimeController";
import { fetchVoicemails } from "../../api/voicemail";
import users from "../../auth/data/users";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import NtErrorView from "../../components/NtErrorView";

class VoicemailController extends BaseMaritimeController {
  state = {
    data: [],
    error: null,
    loading: false,
  };

  componentDidMount() {
    this.handleVoicemailFetch();
  }

  handleVoicemailFetch = async (
    page = 1,
    pageSize = settings.pageSizeLoad,
    filter = null
  ) => {
    this.setState({ loading: true });
    const user = this.context.user?.username;
    const response = await fetchVoicemails(user, page, pageSize);
    Logger("Here are the voicemails: ", response.data);
    if (response.ok) {
      if (page === 1) {
        Logger("First time loading");
        this.state.data = response.data._embedded.voicemail;
        this.setState({ data: this.state.data });
      } else {
        Logger("Adding more");
        this.state.data = this.state.data.concat(
          response.data._embedded.voicemail
        );
        this.setState({
          data: this.state.data,
        });
      }

      if (this.state.data.length === 0) {
        this.setState({ error: "No Voicemails at this time" });
      }
    } else {
      this.setState({
        error: "Unable to load the voicemails. Please try again later",
      });
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer
        showBackButton={true}
        showFooter={true}
        pageTitle={"Voicemails"}
      >
        <View style={{ flex: 1 }}>
          {this.state.error && (
            <NtErrorView
              containerStyle={{ marginTop: 25 }}
              title={"Oops..."}
              message={this.state.error}
            />
          )}
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default VoicemailController;
