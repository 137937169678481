import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../../config/colors";
import NtText from "../../../components/NtText";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import { useMobile } from "../../../hooks/useMobile";
import NtButton from "../../../components/NtButton";
import MyPDF from "../../../assets/files/HAP-Hotels-Case-Study.pdf";

function NtBenefitsSection({
  title = "BENEFITS",
  onLearnMore = null,
  onDownloadCaseStudy = null,
}) {
  const isMobile = useMobile();
  const renderMiniSection = (icon, text) => {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 30,
          },
          isMobile
            ? { backgroundColor: "rgba(13, 12, 45, 0.9)", marginBottom: 6 }
            : { backgroundColor: "rgba(255, 255, 255, 0.1)", width: 230 },
        ]}
      >
        <NtMaterialIcon name={icon} size={isMobile ? 18 : 23} color={"white"} />
        <NtText
          style={[
            {
              marginLeft: 10,
              fontWeight: "300",
              color: "white",
            },
            isMobile ? { fontSize: 12 } : { fontSize: 15 },
          ]}
        >
          {text}
        </NtText>
      </View>
    );
  };
  return (
    <View style={[styles.container]}>
      <Image
        style={[styles.image]}
        source={require("../../../assets/images/marketing-hospitality-benefits-hotel.png")}
      />

      <View
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <NtText
          style={{
            marginTop: 30,
            width: "100%",
            textAlign: "center",
            fontSize: 22,
            fontWeight: "700",
            color: "white",
          }}
        >
          {title}
        </NtText>

        <View
          style={[
            { flex: 1 },
            isMobile ? { flexDirection: "row-reverse" } : {},
          ]}
        >
          <View
            style={[
              {
                flex: 1,
                paddingTop: 15,
                paddingBottom: 25,
                alignItems: "center",
              },
              isMobile ? { justifyContent: "flex-end" } : {},
            ]}
          >
            {onLearnMore && (
              <NtButton
                containerStyle={{
                  width: 110,
                  backgroundColor: colors.lightBlue,
                }}
                text={"Learn More"}
                onPress={onLearnMore}
              />
            )}

            {onDownloadCaseStudy && (
              <a
                style={{ textDecoration: "none" }}
                href={MyPDF}
                download="HAP-Hotels-Case-Study"
                target="_blank"
              >
                <NtButton
                  containerStyle={{
                    width: 180,
                    backgroundColor: colors.lightBlue,
                  }}
                  text={"Download Case Study"}
                />
              </a>
            )}
          </View>
          <View
            style={[
              {
                flex: 1,
                paddingBottom: 20,
                justifyContent: "flex-end",
              },
              isMobile ? { justifyContent: "flex-end" } : {},
            ]}
          >
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  {
                    justifyContent: "space-evenly",
                  },
                  isMobile
                    ? { flexDirection: "column", alignItems: "flex-start" }
                    : { flexDirection: "row", alignItems: "center" },
                ]}
              >
                {renderMiniSection("arrow-down", "Low Cost Solution")}
                {renderMiniSection("phone", "Hotel To Guest Calling")}
                {renderMiniSection(
                  "emoticon-happy-outline",
                  "Increase Engagement"
                )}
              </View>
              <View
                style={[
                  {
                    justifyContent: "space-evenly",
                  },
                  isMobile
                    ? { flexDirection: "column", alignItems: "flex-start" }
                    : {
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 15,
                      },
                ]}
              >
                {renderMiniSection("message-outline", "Guest To Guest Calling")}
                {renderMiniSection("cellphone", "Guests Can BYOD")}
                {renderMiniSection("arrow-up", "Increase Revenue")}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
    minHeight: 400,
  },
  image: {
    height: "100%",
    width: "100%",
  },
});

export default NtBenefitsSection;
