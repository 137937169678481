import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtMaritimeTM({ containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.text]}>NT</NtText>
      <View style={{ width: 5 }} />
      <NtText style={[styles.text, { color: colors.lightBlue }]}>
        MARITIME
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  text: {
    color: "white",
    fontSize: 30,
    fontWeight: "600",
  },
});

export default NtMaritimeTM;
