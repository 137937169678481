import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtDevider from "../../components/NtDevider";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import useSelection from "../../hooks/useSelection";
import { handleCSVDownloadData } from "../utils/ExtensionUploadParser";
import moment from "moment";

function NtExtensionCSVButtonFieldsPicker({ onCancel, data, type }) {
  const columns = [
    {
      title: "Id",
      name: "extension_uuid",
    },
    {
      title: "Extension",
      name: "extension",
      default: true,
    },
    {
      title: "Password",
      name: "password",
    },
    {
      title: "Domain",
      name: "accountcode",
    },
    {
      title: "Caller Id Name",
      name: "effective_caller_id_name",
    },
    {
      title: "Outbound Caller Id Name",
      name: "outbound_caller_id_name",
    },
    {
      title: "Emergency Caller Id Name",
      name: "emergency_caller_id_name",
    },
    {
      title: "Gateway",
      name: "gateway",
      default: true,
    },
    {
      title: "Port",
      name: "port",
      default: true,
    },
  ];

  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const selection = useSelection();

  useEffect(() => {
    //will set the defaults....
    let defaults = [];
    columns.map((each) => {
      if (each?.default) {
        defaults.push(each.title);
      }
    });
    selection.setSelections(defaults);
  }, []);

  const handleFileDownload = async () => {
    let selectedColumns = [];
    columns.map((each) => {
      if (selection.isSelected(each.title)) {
        selectedColumns.push(each);
      }
    });

    const response = await handleCSVDownloadData(data, selectedColumns);
    const fileData = response.data;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${context?.user?.environment?.code}-${moment().format(
      "YYYYMMDD"
    )}-${type}-extensions.csv`;
    link.href = url;
    link.click();
  };

  const isFieldsRequired = () => {
    return selection.selections?.length > 0;
  };

  const renderCancel = () => {
    return (
      <NtTouchableEffect style={styles.cancelButton} onPress={onCancel}>
        <NtText
          style={{ fontSize: 13, fontWeight: "600", color: colors.darkest }}
        >
          Cancel
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderDownload = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          styles.addButton,
          containerStyle,
          isFieldsRequired()
            ? { backgroundColor: colors.blue }
            : { backgroundColor: colors.lighGray },
        ]}
        onPress={() => {
          handleFileDownload();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
          Download
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderButton = (containerStyle, title, onPress) => {
    return (
      <NtTouchableEffect
        style={[
          styles.addButton,
          containerStyle,
          { backgroundColor: colors.blue },
        ]}
        onPress={onPress}
      >
        <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
          {title}
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={item.title}
        onPress={() => {
          if (selection.isSelected(item.title)) {
            selection.removeSelection(item.title);
          } else {
            selection.addSelection(item.title);
          }
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtText
            style={[
              styles.rowText,
              {
                flex: 1,
                color: selection.isSelected(item.title)
                  ? colors.darkest
                  : colors.lighGray,
              },
            ]}
          >
            {item.title}
          </NtText>
          {selection.isSelected(item.title) && (
            <NtMaterialIcon name="check" size={20} color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Download CSV</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select the columns to include in the .csv report.
      </NtText>

      <View style={{ marginTop: 20, marginBottom: 20 }}>
        {columns.map((each, index) => renderRow(each, index))}
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {renderCancel()}
          {renderButton({ marginLeft: 10 }, "Select All", () => {
            let all = [];
            columns.map((each) => {
              all.push(each.title);
            });
            selection.setSelections(all);
          })}
          {renderButton({ marginLeft: 10 }, "Deselect All", () => {
            selection.removeAllSelections();
          })}
        </View>

        {renderDownload()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtExtensionCSVButtonFieldsPicker;
