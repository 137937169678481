import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtDevider from "../../components/NtDevider";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtDisclosure from "../../components/NtDisclosure";
import NtGatwayExtensionItem from "./NtGatewayExtensionItem";

function NtExtensionReportIssueItem({ item, onGatewayPress }) {
  const [isOpen, setIsOpen] = useState(false);
  const renderDesctipion = (containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtTouchableEffect
          onPress={() => {
            if (onGatewayPress) {
              onGatewayPress(item?.gateway);
            }
          }}
        >
          <NtText style={styles.gateway}>Gateway = {item?.gateway}</NtText>
        </NtTouchableEffect>

        <NtText style={[styles.gateway, { marginLeft: 10 }]}>
          Port = {item?.port}
        </NtText>
      </View>
    );
  };

  const renderRow = (item, index) => {
    return (
      <View key={item.extension + index}>
        <View
          style={{ flexDirection: "row", alignItems: "center", padding: 8 }}
        >
          <View style={{ flex: 0.5 }}>
            <NtText style={styles.extension}>
              {item.extension?.substring(4)}
            </NtText>
          </View>
          <View style={{ flex: 1 }}>
            <NtText style={styles.name}>{item.effective_caller_id_name}</NtText>
          </View>
          <View style={{ flex: 1 }}>
            <NtText style={styles.description}>{item.description}</NtText>
          </View>
        </View>
        <NtDevider />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center", padding: 6 }}>
        <NtDisclosure
          name={isOpen ? "chevron-up" : "chevron-right"}
          color={colors.blue}
          onPress={() => {
            setIsOpen(!isOpen);
          }}
        />
        {renderDesctipion({ marginLeft: 10 })}
      </View>

      {isOpen && (
        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          {item.items.map((each, index) => renderRow(each, index))}
        </View>
      )}

      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  gateway: {
    fontSize: 13,
    fontWeight: "600",
    backgroundColor: colors.blue,
    color: "white",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 30,
  },
  extension: {
    fontSize: 15,
    fontWeight: "600",
    color: colors.darkest,
  },
  name: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
  description: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
});

export default NtExtensionReportIssueItem;
