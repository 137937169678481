import { create } from "apisauce";
import settings from "../config/settings";

const clientnodered = create({
  baseURL: settings.apiNoderedUrl,
  headers: { Accept: "application/json" },
});

clientnodered.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/hal+json";
});

export const parseQueryParams = (parameters) => {
  let query = "";
  if (parameters) {
    query += "?";
    const paramsKeys = Object.keys(parameters);
    paramsKeys.forEach((key) => {
      query += `${key}=${parameters[key]}&`;
    });
    query = query.slice(0, -1);
  }
  return query;
};

export default clientnodered;
