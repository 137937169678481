import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import { setStatusBarNetworkActivityIndicatorVisible } from "expo-status-bar";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtTextInput from "../../components/NtTextInput";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import { createAppAccess } from "../../api/appaccess";
import UserContext from "../../context/UserContext";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";

function NtPinManagementNewForm({ onCancel, onCreate }) {
  const [pin, setPin] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return pin && name;
  };

  const handleCreate = async () => {
    setLoading(true);

    // setError(null);
    // const response = await createAppAccess(
    //   `${settings.accountPrefixApp}${extension}@${context.user?.environment?.domain}`,
    //   email
    // );

    // Logger("AppAccess response POST", response);

    // if (response.ok) {
    //   if (onCreate) {
    //     onCreate();
    //   }
    // } else {
    //   if (response.status == 409) {
    //     setError(
    //       `Extension ${extension} does not exist for domain ${context.user?.environment?.domain}`
    //     );
    //   } else {
    //     setError("Unable to process request");
    //   }
    // }

    setLoading(false);
  };

  const renderInput = (
    title,
    placeholder,
    value,
    setValue,
    containerStyle,
    titleStyle
  ) => {
    return (
      <View style={[{ flex: 1 }, containerStyle]}>
        <NtText
          style={[
            styles.inputTitle,
            { marginLeft: 5, marginBottom: 4 },
            titleStyle,
          ]}
        >
          {title}
        </NtText>
        <NtTextInput
          textStyle={{ flex: 1 }}
          containerStyle={[styles.input]}
          placeholder={placeholder}
          placeholderTextColor={colors.lighGray}
          value={value}
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={(text) => setValue(text)}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Add new pin</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will make calling possible for those using pin cards
      </NtText>

      {renderInput(
        "Pin *",
        "Pin",
        pin,
        (text) => {
          setPin(text);
        },
        {
          marginTop: 15,
        },
        { fontWeight: "700" }
      )}

      {renderInput(
        "Name *",
        "Name",
        name,
        (text) => {
          setName(text);
        },
        {
          marginTop: 15,
        },
        { fontWeight: "700" }
      )}
      {error && (
        <NtText
          style={{
            marginTop: 6,
            color: "red",
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {error}
        </NtText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.lighGray,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darker,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: colors.lighestGray,
    borderRadius: 8,
  },
});

export default NtPinManagementNewForm;
