import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useMobile } from "../../hooks/useMobile";
import NtFooterBar from "./NtFooterBar";
import Lottie from "lottie-react";
import NtRootPageContainerHeader from "./NtRootPageContainerHeader";
import colors from "../../config/colors";
import NtRootPageNavigation from "./NtRootPageNavigation";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { navigationRef } from "../../../App";
import routes from "../../navigation/routes";
import NtBackButton from "./NtBackButton";
import NtRootPageFooter from "./NtRootPageFooter";
import NtDevider from "../../components/NtDevider";

function NtRootPageContainer({
  children,
  pageTitle,
  pageSubtitle,
  renderTitleView,
  showFooter = false,
  showSidePannel = false,
  showBackButton = false,
  sidePannelContainerStyle = 0,
  isLoading,
  scrollEnabled = true,
  containerStyle,
}) {
  const isMobile = useMobile();
  const maxWidth = 850;
  const sidePannelWidth = 245;

  return (
    <View style={[styles.container, containerStyle]}>
      <ScrollView
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}
        >
          <View
            style={{
              padding: isMobile ? 15 : 20,
              flex: 1,
              maxWidth: maxWidth,
            }}
          >
            {!renderTitleView && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {showBackButton && (
                  <NtBackButton containerStyle={{ marginRight: 10 }} />
                )}

                <NtRootPageContainerHeader
                  title={pageTitle}
                  subtitle={pageSubtitle}
                  titleSize={isMobile ? 20 : 27}
                  subtitleSize={isMobile ? 18 : 18}
                />
              </View>
            )}
            {renderTitleView && renderTitleView}
            {/* will render the line before.... */}
            {!renderTitleView && (
              <NtDevider containerStyle={{ marginTop: 15 }} />
            )}
            {children}
          </View>
          {!isMobile && showSidePannel && (
            <View
              style={[
                sidePannelContainerStyle,
                {
                  width: sidePannelWidth,
                  paddingRight: 10,
                  paddingLeft: 10,
                },
              ]}
            ></View>
          )}
        </View>

        {showFooter && <NtRootPageFooter />}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    backgroundColor: "white",
  },
});

export default NtRootPageContainer;
