import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import Logger from "../common/utils/Logger";

function NtHoverView({
  children,
  position = "bottom",
  title = "demo",
  enabled = true,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [viewWidth, setViewWidth] = useState(0);
  const [viewHeight, setViewHeight] = useState(0);
  const [hoverWidth, setHoverWidth] = useState(0);
  const [hoverHeight, setHoverHeight] = useState(0);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getStyleForPosition = () => {
    if (position === "bottom") {
      return { top: viewHeight + 5, right: -(hoverWidth / 2 - viewWidth / 2) };
    } else if (position === "right") {
      return {
        right: -(hoverWidth + 5),
        top: -(hoverHeight / 2 - viewHeight / 2),
      };
    }
  };

  return (
    <View style={[styles.container, { width: viewWidth, height: viewHeight }]}>
      <View
        onLayout={(event) => {
          setViewHeight(event.nativeEvent.layout.height);
          setViewWidth(event.nativeEvent.layout.width);
        }}
      >
        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          {children}
        </div>
      </View>

      {enabled && isHovering && (
        <View
          style={[
            styles.hoverContainer,
            { position: "absolute" },
            getStyleForPosition(),
          ]}
          onLayout={(event) => {
            setHoverHeight(event.nativeEvent.layout.height);
            setHoverWidth(event.nativeEvent.layout.width);
          }}
        >
          <NtText numberOfLines={1} style={styles.text}>
            {title}
          </NtText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
  },
  hoverContainer: {
    padding: 6,
    backgroundColor: "#a8a8a8",
    borderRadius: 4,
  },
  text: {
    fontSize: 12,
    color: "white",
    fontWeight: "600",
  },
});

export default NtHoverView;
