import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";

function NtGuestAppSection(props) {
  const isMobile = useMobile();
  return (
    <NtSectionContainer containerStyle={{ paddingBottom: 0 }}>
      <View style={{ width: "100%" }}>
        <Image
          style={[styles.image, isMobile ? { height: 250 } : { height: 460 }]}
          source={require("../../assets/images/marketing-guestapp.jpeg")}
        />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: "100%",
    height: 400,
    resizeMode: "contain",
  },
});

export default NtGuestAppSection;
