import React from "react";
import { View, StyleSheet } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtCard from "../../components/NtCard";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtSemiCircularProgress({
  progress = 100,
  label,
  title = "title",
  loading = false,
  backgroundColor = "#d9d9d9",
}) {
  if (loading) {
    progress = 0;
    backgroundColor = "transparent";
  }

  return (
    <NtCard style={styles.container}>
      <View style={{ padding: 10, alignItems: "center" }}>
        <NtText style={styles.title}>{title}</NtText>
        <AnimatedCircularProgress
          style={{ marginTop: 10 }}
          size={120}
          width={10}
          rotation={270}
          backgroundWidth={5}
          arcSweepAngle={180}
          fill={progress}
          tintColor={colors.blue}
          backgroundColor={backgroundColor}
        >
          {(fill) => (
            <View>
              {!loading && (
                <NtText
                  style={{
                    fontSize: 22,
                    fontWeight: "600",
                    color: colors.blue,
                  }}
                >
                  {label ? label : progress}
                </NtText>
              )}
              {loading && (
                <NtActivityIndicator size="large" color={colors.blue} />
              )}
            </View>
          )}
        </AnimatedCircularProgress>
      </View>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 18,
    fontWeight: "600",
    color: colors.almostBlack,
  },
});

export default NtSemiCircularProgress;
