import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";

function NtHealthContactTracingSection(props) {
  const renderFeature = (image, text, containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <Image
          style={[{ resizeMode: "contain", width: 90, height: 60 }]}
          source={image}
        />
        <NtText
          style={[styles.subtitle, { color: colors.darkBlue, marginLeft: 6 }]}
        >
          {text}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer backgroundColor={colors.lightBlue}>
      <NtText style={styles.title}>Private & Anonymized Contact Tracing</NtText>
      <View style={{ flexDirection: "row", marginTop: 30 }}>
        <Image
          style={[{ resizeMode: "contain", width: 200, height: 200 }]}
          source={require("../../assets/images/marketing-health-contacttracing-map.png")}
        />
        <NtText style={[styles.subtitle, { marginLeft: 40, marginTop: 30 }]}>
          When a contagious guest or crew member has been identified, contact
          tracing helps identify other passengers who may have had significant
          contact with that person so they can also be tested and quarantined,
          if necessary.
        </NtText>
      </View>
      <View style={{ flexDirection: "row", width: "100%", marginTop: 50 }}>
        <View style={{ flex: 1 }}>
          <NtText style={[styles.title, { textAlign: "center" }]}>
            Features and Functionalities
          </NtText>
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox1.png"),
            "Tracing is based on controlled public spaces",
            { marginTop: 20 }
          )}
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox2.png"),
            "Anonymized Public ID is tracked via the app, a wearable, and/or video surveillance",
            { marginTop: 20 }
          )}
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox3.png"),
            "Algorithms will track and instruct the contacts of infected guests to engage in safety protocols",
            { marginTop: 20 }
          )}

          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox4.png"),
            "Guests can be notified by the messaging system and/or voice calls by ship staff",
            { marginTop: 20 }
          )}
        </View>
        <View style={{ flex: 1 }}>
          <NtText style={[styles.title, { textAlign: "center" }]}>
            Benefits
          </NtText>
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox1.png"),
            "Minimize Spread of Disease",
            { marginTop: 20 }
          )}
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox2.png"),
            "Maintain Anonymity whenever possible",
            { marginTop: 20 }
          )}
          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox3.png"),
            "Once a sick person is identified, contact tracing can be enabled to locate, test, and potentially quarantine people at risk.",
            { marginTop: 20 }
          )}

          {renderFeature(
            require("../../assets/images/marketing-health-contacttracing-checkbox4.png"),
            "Identifies locations where an infected person spent time so those areas can receive extra sanitization.",
            { marginTop: 20 }
          )}
        </View>
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: "white",
    fontSize: 25,
    fontWeight: "700",
  },
  subtitle: {
    color: "white",
    fontSize: 14,
    fontWeight: "400",
  },
});

export default NtHealthContactTracingSection;
