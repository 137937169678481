import FontAwesome from "react-native-vector-icons/FontAwesome";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import settings from "../../config/settings";
import { Image } from "react-native";
import { FontAwesome5, Fontisto } from "@expo/vector-icons";

export const messagechanneltypes = [
  {
    id: "app",
    title: "Cruise Line App",
    icon: "ship-wheel",
    // title: "Silversea",
    // renderIcon: (
    //   <Image
    //     style={{ width: 22, height: 22, resizeMode: "contain" }}
    //     source={require("../../assets/images/silverseas-icon.png")}
    //   />
    // ),
    service: "nettalkmaritime.com",
    color: colors.almostBlack,
  },
  {
    id: "sms",
    title: "SMS",
    renderIcon: <FontAwesome5 name={"sms"} size={22} color={colors.darker} />,
    icon: "message-outline",
    service: "sms.omni.nettalkmaritime.com",
    color: colors.darker,
  },
  {
    id: "whatsapp",
    title: "WhatsApp",
    icon: "whatsapp",
    service: "whatsapp.omni.nettalkmaritime.com",
    color: "green",
  },
  {
    id: "imessage",
    title: "Apple Messages for Business",
    icon: "apple",
    service: "apple.omni.nettalkmaritime.com",
    color: colors.almostBlack,
  },
  {
    id: "viber",
    title: "Viber for Business",
    // icon: "viber",
    renderIcon: <Fontisto name={"viber"} size={22} color={"#59267c"} />,
    service: "viber.omni.nettalkmaritime.com",
    color: "#59267c",
  },
  {
    id: "telegram",
    title: "Telegram for Business",
    icon: "telegram",
    service: "telegram.omni.nettalkmaritime.com",
    color: "#4F93DC",
  },
];
