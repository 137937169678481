import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import featurecodedata from "../data/featurecodedata";
import NtFeatureCodeItem from "../components/NtFeatureCodeItem";

class FeatureCodeController extends React.Component {
  state = {};

  renderRow = (item, index) => {
    return <NtFeatureCodeItem key={item.code + index} code={item} />;
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Feature Codes"}
        showFooter={true}
        showBackButton={true}
      >
        <View
          style={{
            marginTop: 25,
            flex: 1,
          }}
        >
          {featurecodedata.map((each, index) => this.renderRow(each, index))}
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default FeatureCodeController;
