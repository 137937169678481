import React from "react";
import { View, StyleSheet, FlatList, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtSectionContainer from "./NtSectionContainer";
import { useMobile } from "../../hooks/useMobile";

function NtHealthScreening(props) {
  const isMobile = useMobile();

  const data = [
    {
      image: require("../../assets/images/marketing-health-screening-heart.png"),
      title: "Heart Rate",
      subtitle: "Noninvasive Pulse Reading and Rhythm",
    },
    {
      image: require("../../assets/images/marketing-health-screening-breatingrate.png"),
      title: "Breathing Rate",
      subtitle: "Noninvasive Respiration Rate and Rhythm",
    },
    {
      image: require("../../assets/images/marketing-health-screening-bodytemperature.png"),
      title: "Body Temperature",
      subtitle: "Core Temperature Fever Detection (coming soon)",
    },
    {
      image: require("../../assets/images/marketing-health-screening-bloodoxygen.png"),
      title: "Blood Oxygen",
      subtitle: "SpO2 Oxygen Saturation (coming soon)",
    },
    {
      image: require("../../assets/images/marketing-health-screening-chatbot.png"),
      title: "Chat-Bot Questionnaire",
      subtitle: "Based on current triage protocols used by hospitals.",
    },
    {
      image: require("../../assets/images/marketing-health-screening-photoid.png"),
      title: "Photo ID",
      subtitle: "To ensure identity, a photo is taken during measurement.",
    },
  ];

  const benefits = [
    {
      image: require("../../assets/images/marketing-health-screening-checkbox.png"),
      title: "Guest-Screening Solution",
    },
    {
      image: require("../../assets/images/marketing-health-screening-checkbox.png"),
      title: "Crew-Screening Solution",
    },
    {
      image: require("../../assets/images/marketing-health-screening-checkbox.png"),
      title: "Screening Triggers",
    },
    {
      image: require("../../assets/images/marketing-health-screening-checkbox.png"),
      title: "Guest/Crew Virtual Check-Up Inside Cabin During Quarantine",
    },
  ];

  const renderRow = (item, index) => {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Image
          style={[{ resizeMode: "contain", width: 50, height: 50 }]}
          source={item.image}
        />
        <NtText
          style={{
            color: colors.lightBlue,
            fontSize: 20,
            textAlign: "center",
            fontWeight: "700",
            marginTop: 15,
          }}
        >
          {item.title}
        </NtText>
        <NtText
          style={{
            color: colors.lighGray,
            fontSize: 16,
            textAlign: "center",
            fontWeight: "700",
            marginTop: 10,
          }}
        >
          {item.subtitle}
        </NtText>
      </View>
    );
  };

  const renderBenefitRow = (item, index) => {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Image
          style={[{ resizeMode: "contain", width: 40, height: 40 }]}
          source={item.image}
        />
        <NtText
          style={{
            color: colors.lighGray,
            fontSize: 16,
            textAlign: "center",
            fontWeight: "700",
            marginTop: 15,
          }}
        >
          {item.title}
        </NtText>
      </View>
    );
  };

  return (
    <NtSectionContainer>
      <NtText style={styles.title}>SCREENING PROCESS</NtText>
      <NtText style={[styles.subtitle, { marginTop: 20 }]}>
        The process uses the passenger’s smartphone and the cruise line’s app
        embedded with the NT Maritime system.
      </NtText>
      <View style={{ marginTop: 40 }}>
        <FlatList
          key={isMobile ? 2 : 3}
          style={styles.flatlist}
          data={data}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 30 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderRow(item, index)}
          numColumns={isMobile ? 2 : 3}
        />
      </View>

      <NtText style={[styles.title, { marginTop: 50 }]}>BENEFITS</NtText>
      <View style={{ marginTop: 40 }}>
        <FlatList
          key={isMobile ? 2 : 4}
          style={styles.flatlist}
          data={benefits}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 30 }} />;
          }}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => renderBenefitRow(item, index)}
          numColumns={isMobile ? 2 : 4}
        />
      </View>
    </NtSectionContainer>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lightBlue,
    fontSize: 25,
    fontWeight: "700",
  },
  subtitle: {
    color: colors.lighGray,
    fontSize: 18,
    textAlign: "center",
    fontWeight: "600",
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: "contain",
  },
});

export default NtHealthScreening;
