import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import colors from "../config/colors";

function NtPageActionButton({ containerStyle, icon, onPress }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtMaterialIcon name={icon} color="white" size={18} />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 25,
    height: 25,
    borderRadius: 18,
    backgroundColor: colors.blue,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtPageActionButton;
