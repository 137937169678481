import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { createExtension } from "../../api/extension";
import { sleep } from "../../common/utils/ControllerUtils";
import { createAppAccess } from "../../api/appaccess";

export const handleAppUploadCreate = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        const success = [];
        const failure = [];

        results.data.map(async (each, index) => {
          const response = await createAppAccess(
            `${settings.accountPrefixApp}${each.extension}@${environment?.domain}`,
            each.email
          );

          Logger("AppAccess upload POST", response);

          //slowing down the request
          await sleep(500);

          if (response.ok) {
            success.push(each);
          } else {
            failure.push({
              request: each,
              status: response.status,
              error: response.data,
            });
          }

          if (success.length + failure.length == results.data.length) {
            resolve({
              ok: true,
              success: success,
              failure: failure,
              total: results.data.length,
            });
          }
        });
      },
    });
  });
};

export const handleAppUPloadFailure = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};
