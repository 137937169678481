import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseExtensionController from "./BaseExtensionController";
import NtFileButton from "../../components/NtFileButton";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import {
  handleExtensionCreate,
  handleFailureFile,
} from "../utils/ExtensionUploadParser";
import Logger from "../../common/utils/Logger";
import NtFileDownloadButton from "../components/NtFileDownloadButton";

class ExtensionUploadController extends BaseExtensionController {
  state = {
    file: null,
    loading: false,
    uploadResponse: null,
  };

  changeHandler = async (event) => {
    this.setState({ file: event.target.files[0], uploadResponse: null });
  };

  handleUpload = async () => {
    this.setState({ loading: true });
    const response = await handleExtensionCreate(this.state.file);

    this.setState({ loading: false, uploadResponse: response });
  };

  handleFailureDownload = async () => {
    const response = await handleFailureFile(this.state.uploadResponse.failure);
    const fileData = response.data;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "failure-uploaded-extension.txt";
    link.href = url;
    link.click();
  };

  renderCSVInfo = () => {
    if (this.state.file) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: colors.lighestGray,
          borderRadius: 8,
          padding: 20,
          marginTop: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <NtMaterialIcon name={"information-outline"} color={colors.lighGray} />
        <NtText style={[styles.infoText, { marginLeft: 10 }]}>
          Please note, the .CSV columns MUST be named (case sensitive):{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            phone
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            extension
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            password
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            name
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            description
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            title
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            location
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            domain
          </NtText>
        </NtText>
      </View>
    );
  };

  renderCSVUploadedInfo = () => {
    if (!this.state.file) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: colors.lighestGray,
          borderRadius: 8,
          marginTop: 20,
          padding: 20,
        }}
      >
        <NtText style={styles.infoText}>File successfully uploaded.</NtText>

        {this.state.uploadResponse && (
          <View style={{ marginTop: 10 }}>
            <NtText style={[styles.infoText, { fontSize: 14 }]}>
              Total Processed {this.state.uploadResponse.total}
            </NtText>
            <NtText style={[styles.infoText, { fontSize: 14, marginTop: 4 }]}>
              Successfully Created {this.state.uploadResponse.success.length}
            </NtText>
            <NtText style={[styles.infoText, { fontSize: 14, marginTop: 4 }]}>
              Failed to Created {this.state.uploadResponse.failure.length}
            </NtText>
          </View>
        )}

        <View
          style={{ marginTop: 20, alignItems: "center", flexDirection: "row" }}
        >
          {!this.state.uploadResponse && (
            <NtFileButton
              containerStyle={{ width: 160 }}
              icon={"progress-check"}
              title={"Process .csv"}
              loading={this.state.loading}
              onPress={() => {
                this.handleUpload();
              }}
            />
          )}

          {this.state.uploadResponse &&
            this.state.uploadResponse.failure.length > 0 && (
              <NtFileDownloadButton
                containerStyle={{ width: 200 }}
                title={"Download Failure"}
                onPress={() => {
                  this.handleFailureDownload();
                }}
              />
            )}
        </View>
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Upload Extensions"}
        showFooter={true}
        showBackButton={true}
      >
        <View
          style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
        >
          <NtFileButton
            title={"Upload .csv"}
            icon={"file-upload-outline"}
            containerStyle={{ width: 130 }}
            onPress={() => {
              document.getElementById("selectFile").click();
            }}
          />

          {this.state.file && (
            <NtText style={[styles.fileTitle, { marginLeft: 6 }]}>
              {this.state.file.name}
            </NtText>
          )}
        </View>

        {this.renderCSVInfo()}

        {this.renderCSVUploadedInfo()}

        <input
          id="selectFile"
          type="file"
          name="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={this.changeHandler}
        />
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  fileTitle: {
    color: colors.lighGray,
    fontSize: 13,
    fontWeight: "800",
  },
  infoText: {
    fontSize: 16,
    fontWeight: "400",
    color: colors.lighGray,
  },
});

export default ExtensionUploadController;
