import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import Logger from "../../common/utils/Logger";
import { fetchExtensions } from "../../api/extension";
import settings from "../../config/settings";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtExtensionReportItem from "../components/NtExtensionReportItem";
import NtExtensionReportTabItem from "../components/NtExtensionReportTabItem";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import NtExtensionReportIssueItem from "../components/NtExtensionReportIssueItem";
import NtExtensionCSVButton from "../components/NtExtensionCSVButton";
import BaseController from "../../common/base/BaseController";

class ExtensionReportController extends BaseController {
  state = {
    search: "",
    data: [],
    master: [],
    loading: false,
    selectedTab: null,
  };

  skip = 0;
  pageSize = 30;
  infiniteScrollEnabled = false;

  tabs = [
    {
      id: "all",
      title: "All",
    },
    {
      id: "room",
      title: "Room",
    },
    {
      id: "ip",
      title: "IP Phones",
    },
    {
      id: "unasign",
      title: "Unasign",
    },
    {
      id: "configured",
      title: "Configured",
    },
    {
      id: "tmp",
      title: "Temporary",
    },
    {
      id: "issues",
      title: "Issues",
    },
  ];

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.state.selectedTab = this.tabs[0];
    this.setState({ selectedTab: this.state.selectedTab });
    this.handleExtensionsFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("----- on focus change");
  };

  handleExtensionsFetch = async () => {
    Logger("Fetching the data for Extensions");

    if (this.skip == 0) {
      this.setState({ loading: true });

      const response = await fetchExtensions(
        this.context.user?.environment?.domain,
        1,
        10000,
        settings.accountPrefixPhy
      );

      if (response.ok) {
        Logger(
          "Loading for the first time, the total items",
          response.data?.extensions?.length
        );

        //will filter base on the tab....
        let master = this.handleTabFilter(response.data.extensions);

        //will sort asc...
        // master = master.sort((a, b) => a.extension - b.extension);

        this.setState({
          master: master,
          data: master.slice(this.skip, this.pageSize),
        });

        this.infiniteScrollEnabled = true;
      }
    } else {
      Logger("Loading more");
      this.setState({
        data: this.state.data.concat(
          this.state.master.slice(this.skip, this.skip + this.pageSize)
        ),
      });
    }

    this.setState({ loading: false });
  };

  handleTabFilter = (list) => {
    let tmpMinNumber = 999990000;
    if (this.context.user?.environment?.code == "FAW") {
      tmpMinNumber = 99998000;
    }

    if (this.state.selectedTab?.id == "room") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < tmpMinNumber
      );
    } else if (this.state.selectedTab.id == "configured") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < tmpMinNumber &&
          each.description?.split(",")[0] > 0
      );
    } else if (this.state.selectedTab?.id == "ip") {
      return list.filter(
        (each) =>
          !each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < tmpMinNumber
      );
    } else if (this.state.selectedTab?.id == "tmp") {
      return list.filter((each) => each.extension >= tmpMinNumber);
    } else if (this.state.selectedTab?.id == "all") {
      return list.filter((each) => each.extension < tmpMinNumber);
    } else if (this.state.selectedTab?.id == "unasign") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < tmpMinNumber &&
          each.description?.split(",")[0] == "0"
      );
    } else if (this.state.selectedTab?.id == "issues") {
      let rooms = list.filter((each) =>
        each.description?.match(/\d+,\d+,\d+,\d+/)
      );

      //will sort...
      rooms = rooms.sort(
        (a, b) => a.description?.split(",")[0] - b.description?.split(",")[0]
      );

      //will first remove all the

      let issuesFound = [];
      rooms.map((each, index) => {
        const eachGateway = each?.description?.split(",")[0];
        const eachPort = each?.description?.split(",")[1];

        if (eachGateway != 0) {
          let dupes = rooms.filter(
            (all) =>
              eachPort == all.description?.split(",")[1] &&
              eachGateway == all.description?.split(",")[0]
          );

          if (
            dupes?.length > 1 &&
            !issuesFound.find(
              (each) => each.gateway == eachGateway && each.port == eachPort
            )
          ) {
            issuesFound.push({
              gateway: eachGateway,
              port: eachPort,
              items: dupes,
            });
          }
        }
      });
      return issuesFound;
    }

    return list;
  };

  renderTabs = () => {
    return (
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 40,
            }}
          >
            {this.tabs.map((each, index) => (
              <NtExtensionReportTabItem
                containerStyle={{ marginRight: 10 }}
                key={index}
                item={each}
                isSelected={each?.title == this.state.selectedTab?.title}
                onPress={() => {
                  if (each.id == this.state.selectedTab?.id) {
                    //no need to reload since we are not changing tabs
                    return;
                  }

                  this.state.selectedTab = each;
                  this.setState({
                    selectedTab: this.state.selectedTab,
                    search: "",
                    data: [],
                    master: [],
                  });
                  this.skip = 0;
                  this.handleExtensionsFetch();
                }}
              />
            ))}
          </View>
        </ScrollView>
      </View>
    );
  };

  renderRow = (item, index) => {
    if (this.state.selectedTab?.id == "issues") {
      return (
        <NtExtensionReportIssueItem
          key={index}
          item={item}
          onGatewayPress={(gateway) => {
            this.props.navigation.navigate(routes.gateway, {
              gateway: gateway,
            });
          }}
        />
      );
    }
    return (
      <NtExtensionReportItem
        key={index}
        item={item}
        onGatewayPress={(gateway) => {
          this.props.navigation.navigate(routes.gateway, { gateway: gateway });
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Extension Report"}
        showFooter={true}
        showBackButton={true}
      >
        {this.renderTabs()}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtText style={styles.title}>
            Records Found{" "}
            <NtText style={[styles.title, { fontWeight: "700" }]}>
              {this.state.master?.length}
            </NtText>
          </NtText>
          <NtExtensionCSVButton
            containerStyle={{ marginLeft: 30 }}
            data={this.state.master}
            type={this.state.selectedTab?.id}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Extensions"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.skip = this.skip + this.pageSize;
                  this.handleExtensionsFetch();
                }}
              />
            </View>
          }
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default ExtensionReportController;
