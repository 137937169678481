import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtUnderConstruction from "../../components/NtUnderConstruction";
import NtText from "../../components/NtText";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtModal from "../../components/NtModal";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import NtPinManagementNewForm from "../components/NtPinManagementNewForm";

class PinManagementController extends BaseController {
  state = {
    loading: false,
    deleting: false,
    data: [],
    master: [],
  };

  skip = 0;
  pageSize = 30;
  infiniteScrollEnabled = false;
  addAccessModalRef = React.createRef();
  uploadAccessModalRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDelete = async (id) => {
    this.setState({ deleting: true });

    // const response = await deleteAppAccess(id);
    // if (response.ok) {
    //   showToast("Success", "Successfully deleted the app access.");
    //   this.skip = 0;
    //   this.handleDataFetch();
    // }

    this.setState({ deleting: false });
  };

  handleDataFetch = async () => {
    Logger("Fetching App Access data");

    // if (this.skip == 0) {
    //   this.setState({ loading: true });

    //   const response = await fetchAppAccess(1, 10000);
    //   Logger("Data response GET: ", response);

    //   if (response.ok) {
    //     Logger(
    //       "Loading for the first time, the total items",
    //       response.data?.securityCodes?.length
    //     );

    //     let master = response.data?.securityCodes;

    //     this.setState({
    //       master: master,
    //       data: master.slice(this.skip, this.pageSize),
    //     });

    //     this.infiniteScrollEnabled = true;
    //   }
    // } else {
    //   Logger("Loading more");
    //   this.setState({
    //     data: this.state.data.concat(
    //       this.state.master.slice(this.skip, this.skip + this.pageSize)
    //     ),
    //   });
    // }

    this.setState({ loading: false });
  };

  renderAddNewForm = () => {
    return (
      <NtModal
        ref={this.addAccessModalRef}
        renderBody={
          <NtPinManagementNewForm
            onCancel={() => {
              this.addAccessModalRef.current?.dismiss();
            }}
            onCreate={() => {
              this.skip = 0;
              this.handleDataFetch();
              this.addAccessModalRef.current?.dismiss();
              showToast("Success", "Successfully created the new app access");
            }}
          />
        }
      />
    );
  };

  renderUploadForm = () => {
    return <NtModal ref={this.uploadAccessModalRef} renderBody={<View />} />;
  };

  renderRow = (item, index) => {
    return null;
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Pin Management"}
        showFooter={true}
        showBackButton={true}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtText style={styles.title}>
            Records Found {this.state.master?.length}
          </NtText>

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
          <NtPageActionButton
            icon={"arrow-up"}
            onPress={() => {
              this.uploadAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.skip = this.skip + this.pageSize;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderUploadForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default PinManagementController;
