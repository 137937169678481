import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";

function NtAppAccessItem({ item, onDelete }) {
  return (
    <View style={styles.container}>
      <View style={{ padding: 6, flexDirection: "row", alignItems: "center" }}>
        <View style={{ flex: 1 }}>
          <NtText style={styles.extension}>
            {item.extension.split("@")[0]?.substring(4)}
          </NtText>
        </View>
        <View style={{ flex: 1 }}>
          <NtText style={styles.description}>{item.email}</NtText>
        </View>
        <NtTouchableEffect onPress={onDelete}>
          <NtMaterialIcon name="delete" size={18} />
        </NtTouchableEffect>
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  extension: {
    fontSize: 15,
    fontWeight: "600",
    color: colors.darkest,
  },
  description: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
});

export default NtAppAccessItem;
