import React, { useState, useContext } from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtDevider from "../../components/NtDevider";
import NtTextInput from "../../components/NtTextInput";
import { messagechanneltypes } from "../data/messagechanneltypes";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtChannelIcon from "./NtChannelIcon";

function NtMessageChannelCreateModal({ onCancel, onCreate, directory }) {
  const [channelSelection, setChannelSelection] = useState(null);
  const [destination, setDestination] = useState([]);
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequired = () => {
    return channelSelection && destination?.length > 0 && !error;
  };

  const isSuggestionViewOpened = () => {
    return !(suggestions?.length == 0 && destination?.length == 0);
  };

  const handleSuggestion = (query) => {
    if (query?.length > 2) {
      setSuggestions(
        directory?.filter(
          (each) =>
            each.number != context.user?.username?.toString() &&
            (each.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
              each.number?.includes(query))
        )
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleErrorCheck = (dest = destination, channel = channelSelection) => {
    if (
      dest?.length > 1 &&
      channel &&
      channel?.id != messagechanneltypes[0].id
    ) {
      setError(
        `Oops... multiple destinations are only allow on channel "${messagechanneltypes[0].title}"`
      );
    } else {
      setError(null);
    }
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return (
      <View
        style={{ marginTop: 18, flexDirection: "row", alignItems: "center" }}
      >
        <NtMaterialIcon
          name="alert-outline"
          containerStyle={{ marginRight: 6 }}
        />
        <NtText style={styles.errorText}>{error}</NtText>
      </View>
    );
  };

  const renderDestinationRow = (item, index) => {
    return (
      <View
        key={index}
        style={{
          borderWidth: 1,
          borderColor: colors.almostBlack,
          borderRadius: 25,
          paddingLeft: 14,
          paddingRight: 12,
          paddingTop: 4,
          paddingBottom: 4,
          flexDirection: "row",
          alignItems: "center",
          marginRight: 8,
          marginBottom: 4,
        }}
      >
        <View style={{ marginRight: 8 }}>
          <NtText style={styles.destinationTitle}>{item.name}</NtText>
          <NtText style={[styles.destinationNumber, { marginTop: 2 }]}>
            {item.number}
          </NtText>
        </View>
        <NtTouchableEffect
          onPress={() => {
            setDestination(
              destination?.filter((each) => each.number != item.number)
            );

            handleErrorCheck(
              destination?.filter((each) => each.number != item.number),
              channelSelection
            );
          }}
        >
          <NtMaterialIcon name="trash-can-outline" />
        </NtTouchableEffect>
      </View>
    );
  };

  const renderSuggestionRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={item.id + index}
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={() => {
          if (!destination?.find((each) => each.number == item.number)) {
            setDestination([...destination, item]);
            setInput("");
            handleSuggestion("");
            handleErrorCheck([...destination, item], channelSelection);
          }
        }}
      >
        <NtMaterialIcon name="account-circle-outline" color={colors.darkest} />
        <View style={{ paddingTop: 8, paddingBottom: 8, marginLeft: 8 }}>
          <NtText style={styles.destinationTitle}>{item.name}</NtText>

          <NtText style={[styles.destinationNumber, { marginTop: 2 }]}>
            {item.number}
          </NtText>
        </View>
      </NtTouchableEffect>
    );
  };

  const renderSuggestions = () => {
    if (!isSuggestionViewOpened()) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: "red",
          width: "100%",
          maxHeight: 200,
          backgroundColor: colors.lighestGray,
          borderRadius: 30,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          padding: 15,
        }}
      >
        {destination?.length > 0 && (
          <NtText>
            {destination?.map((each, index) =>
              renderDestinationRow(each, index)
            )}
          </NtText>
        )}

        <ScrollView
          contentContainerStyle={{ paddingBottom: 0 }}
          showsVerticalScrollIndicator={false}
        >
          {suggestions?.map((each, index) => renderSuggestionRow(each, index))}
        </ScrollView>
      </View>
    );
  };

  const renderChannelRow = (item, index) => {
    const selected = channelSelection?.id == item?.id;
    return (
      <NtTouchableEffect
        key={item.id}
        style={[
          { paddingLeft: 8, paddingRight: 8 },
          selected ? { backgroundColor: colors.blue, borderRadius: 10 } : {},
        ]}
        onPress={() => {
          setChannelSelection(item);
          handleErrorCheck(destination, item);
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtChannelIcon channel={item} color={selected ? "white" : null} />

          <NtText
            style={[
              styles.channelTitle,
              { marginLeft: 10, flex: 1 },
              selected ? { color: "white" } : {},
            ]}
          >
            {item.title}
          </NtText>
          {selected && <NtMaterialIcon name="check" color={"white"} />}
        </View>

        {!selected && <NtDevider />}
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>New Conversation</NtText>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Create new conversation channel
      </NtText>

      <View style={{ marginTop: 30 }}>
        <NtText style={[styles.sectionTitle, { marginBottom: 8 }]}>
          Find Guest
        </NtText>
        <NtTextInput
          icon={"magnify"}
          bgColor={colors.lighestGray}
          placeholder="Directory lookup"
          value={input}
          onChangeText={(text) => {
            setInput(text);
            handleSuggestion(text);
          }}
          containerStyle={[
            { flex: 1, borderRadius: 30 },
            isSuggestionViewOpened()
              ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }
              : {},
          ]}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          textColor={colors.darkest}
        />

        {renderSuggestions()}

        <NtText
          style={[styles.sectionTitle, { marginTop: 15, marginBottom: 8 }]}
        >
          Select Messaging Channel
        </NtText>
        {messagechanneltypes.map((each, index) =>
          renderChannelRow(each, index)
        )}
      </View>

      {renderError()}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 20 }}
          title={"create"}
          onPress={() => {
            if (onCreate && isRequired()) {
              onCreate({ channel: channelSelection, destination: destination });
            }
          }}
          enabled={isRequired()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  sectionTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  channelTitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "400",
  },
  destinationTitle: {
    color: colors.almostBlack,
    fontSize: 12,
    fontWeight: "600",
  },
  destinationNumber: {
    color: colors.darker,
    fontSize: 11,
    fontWeight: "400",
  },
  errorText: {
    color: "red",
    fontSize: 12,
    fontWeight: "600",
  },
});

export default NtMessageChannelCreateModal;
