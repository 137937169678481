import { generateFullUser } from "../MessagingClient";
class RecordItem {
  constructor(address) {
    if (address.includes("@")) {
      this.mAddress = address;
    } else {
      this.mAddress = generateFullUser(address);
    }
  }

  getAddress = () => {
    return this.mAddress;
  };

  isGroup = () => {
    return this.mAddress.includes(":");
  };
}

export default RecordItem;
