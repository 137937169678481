import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtButton from "../../components/NtButton";
import NtCard from "../../components/NtCard";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import { useMobile } from "../../hooks/useMobile";
import Logger from "../../common/utils/Logger";

function NtLatestNewsCard({ item, onPress }) {
  const isMobile = useMobile();
  return (
    <NtCard style={styles.container}>
      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          margin: isMobile ? 0 : 30,
        }}
      >
        <Image
          style={[styles.image, isMobile ? { width: 300, height: 200 } : {}]}
          source={item.image}
        />
        <View style={{ flex: 1, marginLeft: isMobile ? 0 : 20 }}>
          <NtText style={styles.source}>{item.source}</NtText>
          <NtText style={[styles.date, { marginTop: 8 }]}>{item.date}</NtText>
          <NtText
            style={[styles.description, { marginTop: 15 }]}
            numberOfLines={2}
          >
            {item.description}
          </NtText>
          <NtButton
            containerStyle={{ width: 100, marginTop: 15 }}
            text={"Read More"}
            onPress={() => {
              if (onPress) {
                onPress(item);
              }
            }}
          />
        </View>
      </View>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 400,
    height: 300,
    resizeMode: "contained",
  },
  source: {
    fontSize: 18,
    fontWeight: "600",
    color: colors.darkest,
  },
  date: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darkest,
  },
  description: {
    fontSize: 20,
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtLatestNewsCard;
