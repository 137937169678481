import React from "react";
import { View, StyleSheet, Image } from "react-native";
import AppNavigator from "../../navigation/AppNavigator";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import RootController from "./RootController";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
} from "react-pro-sidebar";
import colors from "../../config/colors";
import { navigationRef } from "../../../App";

class RootAppController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
    sidebarCollapsed: this.context.user?.ui
      ? this.context.user?.ui?.sidebarcollapsed
      : true,
  };

  options = [
    {
      icon: "gauge",
      title: "Dashboard",
      action: () => {
        navigationRef.current.navigate(routes.dashboard);
      },
      isSelected: () => {
        return (
          navigationRef.current?.getCurrentRoute()?.name == routes.dashboard
        );
      },
    },
    {
      icon: "gamepad-circle-outline",
      title: "Omni Channel",
      action: () => {
        navigationRef.current.navigate(routes.messagev2);
      },
      isSelected: () => {
        return (
          navigationRef.current?.getCurrentRoute()?.name == routes.messagev2
        );
      },
    },
    {
      icon: "tools",
      title: "SDK Documents",
      action: () => {
        navigationRef.current.navigate(routes.docs);
      },
      isSelected: () => {
        return navigationRef.current?.getCurrentRoute()?.name == routes.docs;
      },
    },
    {
      icon: "receipt",
      title: "Billing",
      isSelected: () => {
        let response = false;
        this.options
          ?.find((each) => each.title == "Billing")
          ?.items?.map((each) => {
            response = response || each.isSelected();
          });

        return response;
      },
      items: [
        {
          icon: "dialpad",
          title: "Pin Management",
          description: "Create extensions from .csv",
          action: () => {
            navigationRef.current.navigate(routes.pinmanagement);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name ==
              routes.pinmanagement
            );
          },
        },
      ],
    },
    {
      icon: "deskphone",
      title: "PBX",
      isSelected: () => {
        let response = false;
        this.options
          ?.find((each) => each.title == "PBX")
          ?.items?.map((each) => {
            response = response || each.isSelected();
          });

        return response;
      },
      items: [
        {
          icon: "file-upload-outline",
          title: "Upload Extensions",
          description: "Create extensions from .csv",
          action: () => {
            navigationRef.current.navigate(routes.upload);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name == routes.upload
            );
          },
        },
        {
          icon: "phone",
          title: "Create Extension",
          description: "Create single extension",
          action: () => {
            navigationRef.current.navigate(routes.extensioncreate);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name ==
              routes.extensioncreate
            );
          },
        },
        {
          icon: "newspaper-variant-outline",
          title: "Extension Report",
          description: "View extension report",
          action: () => {
            navigationRef.current.navigate(routes.extensionreport);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name ==
              routes.extensionreport
            );
          },
        },
        {
          icon: "server",
          title: "Gateway Report",
          description: "View gateway report",
          action: () => {
            navigationRef.current.navigate(routes.gatewayreport);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name ==
              routes.gatewayreport
            );
          },
        },
        {
          icon: "dialpad",
          title: "App Access",
          description: "Configure PIN Access for apps",
          action: () => {
            navigationRef.current.navigate(routes.appaccess);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name == routes.appaccess
            );
          },
        },
        {
          icon: "asterisk",
          title: "Feature Codes",
          description: "Feature code list",
          action: () => {
            navigationRef.current.navigate(routes.featurecode);
          },
          isSelected: () => {
            return (
              navigationRef.current?.getCurrentRoute()?.name ==
              routes.featurecode
            );
          },
        },
      ],
    },
  ];

  pbxItems = [];

  componentDidMount() {
    super.componentDidMount();
  }

  showSidePannel = () => {
    return (
      this.state.currentController !== routes.notFound &&
      this.state.currentController !== routes.marketing &&
      this.state.currentController !== routes.health &&
      this.state.currentController !== routes.guestapp &&
      this.state.currentController !== routes.crewapp &&
      this.state.currentController !== routes.pbxsystem &&
      this.state.currentController !== routes.hospitalitypbx &&
      this.state.currentController !== routes.starlink
    );
  };

  isPBXSelected = () => {
    let response = false;
    this.pbxItems.map((each) => {
      response = response || each.isSelected();
    });

    return response;
  };

  renderMenu = (item) => {
    if (item?.items) {
      return (
        <SubMenu
          key={item.title}
          icon={
            <NtMaterialIcon name={item.icon} color={colors.blue} size={24} />
          }
          style={{
            ...styles.menu,
            backgroundColor: item.isSelected() ? colors.lighestGray : null,
          }}
          label={item.title}
        >
          {item?.items?.map((each) => (
            <MenuItem
              key={each.title}
              icon={
                <NtMaterialIcon
                  name={each.icon}
                  color={colors.blue}
                  size={18}
                />
              }
              onClick={() => {
                each.action();
              }}
              style={{
                ...styles.menu,
                fontSize: 12,
                fontWeight: "500",
                backgroundColor: each?.isSelected() ? colors.lighestGray : null,
              }}
            >
              {each.title}
            </MenuItem>
          ))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          key={item.title}
          icon={
            <NtMaterialIcon name={item.icon} color={colors.blue} size={18} />
          }
          onClick={() => {
            item.action();
          }}
          style={{
            ...styles.menu,
            backgroundColor: item?.isSelected() ? colors.lighestGray : null,
          }}
        >
          {item.title}
        </MenuItem>
      );
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          {this.showSidePannel() && (
            <Sidebar style={{}} collapsed={this.state.sidebarCollapsed}>
              <View style={{ height: "100%" }}>
                {/* header */}
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    paddingTop: 10,
                  }}
                >
                  <Image
                    style={{ width: 35, height: 35, resizeMode: "contain" }}
                    source={require("../../assets/images/favicon-big-blue.png")}
                  />
                  {!this.state.sidebarCollapsed && (
                    <View style={{ marginLeft: 10 }}>
                      <NtText
                        style={{
                          fontSize: 16,
                          fontWeight: "800",
                          color: colors.almostBlack,
                        }}
                      >
                        {this.context?.user?.name}
                      </NtText>
                      <NtText
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          color: colors.almostBlack,
                        }}
                      >
                        {this.context?.user?.username}
                      </NtText>
                    </View>
                  )}
                </View>

                <Menu style={{ marginTop: 50 }}>
                  {this.options.map((each) => this.renderMenu(each))}
                </Menu>

                <View style={{ flex: 1 }} />

                <View style={{ paddingBottom: 30 }}>
                  <Menu>
                    <MenuItem
                      icon={<NtMaterialIcon name={"logout"} color={"red"} />}
                      onClick={() => {
                        if (this.context.user.authenticated) {
                          this.context.updateUser({});
                        }

                        setTimeout(() => {
                          navigationRef.current.navigate(routes.auth);
                        }, 500);
                      }}
                      style={{ ...styles.menu, color: "red" }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </View>
              </View>
            </Sidebar>
          )}

          <View style={{ flex: 1 }}>
            {this.showSidePannel() && (
              <NavigationBar
                sidebarCollapsed={this.state.sidebarCollapsed}
                onSidebarToggle={() => {
                  this.state.sidebarCollapsed = !this.state.sidebarCollapsed;

                  this.context.updateUser({
                    ...this.context.user,
                    ui: { sidebarcollapsed: this.state.sidebarCollapsed },
                  });

                  this.setState({
                    sidebarCollapsed: this.state.sidebarCollapsed,
                  });
                }}
              />
            )}
            <AppNavigator
              onControllerChanged={(controller) => {
                Logger("Current controller: ", controller);
                this.setState({ currentController: controller });
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
  menu: {
    fontFamily: "Helvetica",
    color: colors.almostBlack,
    fontWeight: "600",
    fontSize: 13,
  },
});

export default RootAppController;
