import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import { useMobile } from "../../hooks/useMobile";
import NtSectionContainer from "./NtSectionContainer";

function NtGuestAppVideoSection(props) {
  const isMobile = useMobile();
  const renderIcon = (image, text, containerStyle) => {
    return (
      <View style={[{ alignItems: "center" }, containerStyle]}>
        <Image
          style={[styles.icons, isMobile ? { width: 50, height: 50 } : {}]}
          source={image}
        />
        <NtText
          style={{
            textAlign: "center",
            color: "white",
            fontSize: 12,
            fontWeight: "600",
            marginTop: 8,
          }}
        >
          {text}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Image
        style={styles.background}
        source={require("../../assets/images/water-background.png")}
      />

      <NtSectionContainer
        backgroundColor="transparent"
        containerStyle={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <View
          style={{
            width: "100%",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <View style={{ flex: 1 }}>
            <NtText style={styles.title}>Guest-Video</NtText>
            <NtText style={[styles.subtitle, { marginTop: 30 }]}>
              Onboard / Ship to Ship / Ship to Shore
            </NtText>
            <NtText style={[styles.subtitle, { marginTop: 30 }]}>
              Passengers can conduct real-time video calls with other passengers
              on the same ship
            </NtText>
          </View>

          <Image
            style={[
              styles.image,
              isMobile ? { height: 200, marginTop: 30 } : { height: 350 },
            ]}
            source={require("../../assets/images/marketing-guestapp-video.png")}
          />
        </View>
      </NtSectionContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: "center" },
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  image: {
    width: 200,
    height: 300,
    resizeMode: "contain",
  },
  title: {
    fontSize: 30,
    fontWeight: "800",
    color: "white",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "600",
    color: "white",
    textAlign: "center",
  },
  text: {
    fontSize: 20,
    fontWeight: "400",
    color: "white",
  },
});

export default NtGuestAppVideoSection;
